import React from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import styled, { keyframes, css } from 'styled-components';
import Text from './Text';
import { Chevron } from '../../utility/icons';
import { asyncDelay } from '../../utility/helpers';
import styles from '../../utility/styles';

const DURATION = 0.3;
const DELAY = 0.5;

// animations

const rotateDown = keyframes`
    from { transform: rotate(0) }
    to { transform: rotate(180deg) }
`;

const rotateUp = keyframes`
    from { transform: rotate(180deg) }
    to { transform: rotate(0) }
`;

const rotation = props =>
    css`
        ${props.open === null ? 'none' : props.open ? rotateDown : rotateUp} ${DURATION}s ease-in-out forwards;
    `

const Divider = styled.div`
    width: 100%;
    height: 0;
    border-top: 1px solid ${styles.colors.darkGray};
`;

const AnimatedPara = styled(Text.Para)`
    color: ${({ open }) => open ? `${styles.colors.white}` : `${styles.colors.whiteGray}`};
    padding: 24px 0;
`;

const BodyDiv = styled.div`
    color: ${({ open }) => open ? `${styles.colors.white}` : `${styles.colors.whiteGray}`};
    padding: 24px 0;
    &>p {
        font-family: ${styles.fonts.nunito};
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: ${styles.colors.whiteGray};
        margin: 0
    }
`;

const AnimatedTitle = styled(Text.Heading)`
    color: ${({ open }) => open ? `${styles.colors.white}` : `${styles.colors.whiteGray}`};
    padding: 24px 0;
`;

const StyledButton = styled.button`
    background: transparent;
    border: none;
    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${rotation}
`;

/**
 * 
 * @param {{heading:string, useParser:boolean, body:string, slug:string, openedSlug:string}} props
 * @returns 
 */
function AccordionRow({ heading, useParser, body, slug, openedSlug }) {
    const [open, setOpen] = React.useState(null);
    const [colWidth, setColWidth] = React.useState(12);
    const ref = React.useRef();
    const collapseRef = React.useRef();

    const toggleOpen = React.useCallback( async () => {
        if (!open) {
            setColWidth(4);
            await asyncDelay(DELAY * 1000);
            setOpen(true);
        } else {
            setOpen(false)
        }
    }, [open, setOpen, setColWidth])

    const onEntered = React.useCallback(() => {
        if (ref.current) ref.current.scrollIntoView({behavior: 'smooth'})
    }, [ref])

    const onExited = React.useCallback(() => {
        setColWidth(12);
    }, [setColWidth])

    React.useEffect(() => {
        const openActiveSlug = async () => {
            setColWidth(4);
            await asyncDelay(DELAY * 1000);
            setOpen(true)
        }

        if (slug && (slug === openedSlug)) {
            // force this slug open
            openActiveSlug()
        }
    }, [openedSlug, slug, setColWidth, setOpen])

    return (
        <Row {...{ ref }} id={slug} className='d-flex justify-content-between'>
            <Divider />
            <Col xs={11}>
                <Row>
                    <Col md={colWidth} sm={12} style={{ transition: `width ${DELAY}s ease-in-out 0s` }}>
                        <AnimatedTitle variant='bold' type='h5' {...{ open }} >{heading}</AnimatedTitle>
                    </Col>
                    <Collapse ref={collapseRef} in={open} {...{ onEntered, onExited }}>
                        <Col md={12 - colWidth} sm={12} style={{}}>
                            {useParser ? (
                                <BodyDiv>{ReactHtmlParser(body)}</BodyDiv>
                            ) : (
                                <AnimatedPara {...{ open }}>{body}</AnimatedPara>
                            )}
                        </Col>
                    </Collapse>
                </Row>
            </Col>
            <Col xs={1}>
                <StyledButton onClick={toggleOpen} {...{ open }}>
                    <Chevron />
                </StyledButton>
            </Col>
        </Row>
    )
}

/**
 * 
 * @param {{title:string, useParser?:boolean, rows: {title:string, body:string, slug:string}[], openSlug:string, style?:React.CSSProperties}} props
 * @returns 
 */
export default function Accordion({ title, useParser = false, rows = [], openedSlug, style }) {
    return (
        <div {...{ style }}>
            {Boolean(title) && <Text.Heading type='h4' variant='bold' style={{ marginBottom: '48px' }}>{title}</Text.Heading>}
            {rows.map((row, i) => <AccordionRow key={`row-${i + 1}`} {...{ openedSlug, useParser }} {...row} />)}
            <Divider />
        </div>
    )
}