import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import styled from 'styled-components';
import Button from '../shared/Button';
import ScreenWrapper from '../shared/ScreenWrapper';
import Text from '../shared/Text';
import styles from '../../utility/styles';

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 24px;
    background-color: ${styles.colors.darkGray};
`;

export default function AppLink({ path }) {

    React.useEffect(() => {
        if (isMobile) {
            window.location.href = `tezlab://${path}`
        }
    }, [path])

    return (
        <ScreenWrapper>
            <Container>
                <Row>
                    <Col>
                        <TextWrapper>
                            {isMobile ? (
                                <React.Fragment>
                                    <Text.Heading type='h5' variant='bold' style={{ marginBottom: '24px' }}>This link opens in TezLab</Text.Heading>
                                    <Button as='a' href={`tezlab://${path}`}>Launch TezLab</Button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Text.Heading type='h5' variant='bold' style={{ marginBottom: '24px' }}>Sorry, this link must be opened on a mobile device.</Text.Heading>
                                    <Text.Body>Please open the link again on your iOS or Android device to launch TezLab.</Text.Body>
                                </React.Fragment>
                            )}
                        </TextWrapper>
                    </Col>
                </Row>
            </Container>
        </ScreenWrapper>
    )
}