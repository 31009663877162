import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { get, useStandardState } from '../../../utility/api';
import { SessionProvider } from '../../../utility/hooks';
import Text from '../../shared/Text';
import Spinner from '../../shared/Spinner';
import ScreenWrapper from '../../shared/ScreenWrapper';
import Header from './Header';
import Body from './Body';
import CodeSection from './CodeSection';
import styles from '../../../utility/styles';
const INTERVAL = 10000;

const StyledContainer = styled(Container)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: ${({ justify }) => justify || 'space-between'};
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function WebDash({ session_id }) {

    const [{ data, error, isFetching }, { startFetching, recieveData, recieveError }] = useStandardState();
    const [initalLoading, setInitialLoading] = React.useState(true);
    const loadInterval = React.useRef(null);

    const loadData = React.useCallback(async () => {
        if (isFetching) return
        try {
            startFetching();
            const { data, error } = await get(`/v2/dash/${session_id}/data`);
            if (error) throw error
            if (data) recieveData(data)
            setInitialLoading(false);
        } catch (error) {
            recieveError(typeof error === 'string' ? error : "Yipes! We ran into an issue. Hang tight a few seconds and we'll try again.");
            setInitialLoading(false);
        }
    }, [isFetching, startFetching, recieveData, recieveError, setInitialLoading]);

    React.useEffect(() => {
        loadData();
        loadInterval.current = setInterval(loadData, INTERVAL)
        return () => clearInterval(loadInterval.current)
    }, []);

    React.useEffect(() => {
        localStorage.setItem("wdsid", JSON.stringify(session_id));
    }, [])

    if (!data?.authenticated || initalLoading) {
        return (
            <SessionProvider {...{ session_id }}>
                <ScreenWrapper
                    noFooter
                    justify="center"
                >
                    <StyledContainer>
                        <Row>
                            <Col>
                                {initalLoading ? (
                                    <LoadingContainer>
                                        <Text.Heading style={{ marginRight: '24px' }}>Loading</Text.Heading>
                                        <Spinner color={styles.colors.white} size={40} />
                                    </LoadingContainer>
                                ) : (
                                    <CodeSection {...{ error, isFetching }} />
                                )}
                            </Col>
                        </Row>
                    </StyledContainer>
                </ScreenWrapper>
            </SessionProvider>
        )
    }

    return (
        <SessionProvider {...{ session_id }}>
            <StyledContainer fluid>
                <Header booleans={{ is_driving: data.is_driving, is_charging: data.is_charging }} status={data.vehicle_status} lastFetched={data.timestamp} />
                <Body booleans={{ is_driving: data.is_driving, is_charging: data.is_charging }} status={data.vehicle_status} {...{ data }} />
            </StyledContainer>
        </SessionProvider>
    )
}
