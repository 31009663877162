import React from 'react'
import styled from 'styled-components';
import Media from './Media';
import Avatar from './Avatar';
import styles from '../../../utility/styles';
import moment from 'moment';
import Text from '../Text';

const Wrapper = styled.div`
    border-radius: 8px;
    display: flex;
    flex-direction: ${({type}) => type === 'zap_post' ? 'row' : 'column'};
    max-width: 500px;
    margin: auto;
    overflow: hidden;
    @media (max-width: 760px) {
        margin: 0 24px;
    }
    @media (max-width: 475px) {
        flex-direction: column;
    }
`;

const BodyWrapper = styled.div`
    padding: 16px;
    background-color: ${styles.colors.darkGray};
    display: flex;
    flex: 1;
    padding-left: ${({type}) => type === 'zap_post' ? '48px' : '16px'};
    flex-direction: column;
    justify-content: ${({type}) => type === 'zap_post' ? 'center' : 'space-between'};
`;

const Body = styled(Text.Body)`
    margin-bottom: 12px;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    alignItems: center;
`;

const Location = styled.div`
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    background-color: ${styles.colors.lightGray};
    color: ${styles.colors.darkGray};
    padding: 4px; 8px;
    border-radius: 2px;
`;

const TimeStamp = styled(Text.Tiny)`
    color: ${styles.colors.lightGray};
`;

const returnDateString = timestamp => {
    const compareMoment = moment(timestamp);
    const yesterdayMoment = moment().subtract(1, "day");
    const todayMoment = moment();
    if (compareMoment.isSame(todayMoment, "day")) {
      return "Today";
    } else if (compareMoment.isSame(yesterdayMoment, "day")) {
      return "Yesterday";
    } else {
      return compareMoment.isSame(todayMoment, 'year') ? compareMoment.format("MMM D") : compareMoment.format("MMM D, YYYY");
    }
  }

const isMoreThanOneDay = (start_ts, end_ts) => {
    const startMoment = moment(start_ts);
    const endMoment = moment(end_ts);
    return !startMoment.isSame(endMoment, 'day');
}

const returnTimeAgoText = timestamp => {
    if (!timestamp) return ''
    const showDate = isMoreThanOneDay(timestamp * 1000, moment());
    const timeAgo = showDate ? returnDateString(timestamp * 1000) : moment(timestamp * 1000).from(moment(), false)
    return timeAgo
}

export default function Post({ user, media, body, location, timestamp, hideMedia = false, type, children }) {

    const timeAgo = returnTimeAgoText(timestamp);

    return (
        <Wrapper {...{type}}>
            {type !== 'zap_post' && <Media.MediaPost {...{hideMedia, media}} />}
            {type === 'zap_post' && <Media.ZapPost {...{hideMedia, media}} />}
            <BodyWrapper {...{type}}>
                <Avatar.ZapAvatar {...{type, user}}>
                    <Body>{body}</Body>
                </Avatar.ZapAvatar>
                {children}
                <Footer>
                    {Boolean(location) && type !== 'zap_post' && <Location>{location}</Location>}
                    {!Boolean(location) && type !== 'zap_post' && <div />}
                    <TimeStamp {...{type}}>{timeAgo}</TimeStamp>
                </Footer>
            </BodyWrapper>
        </Wrapper>
    )
}
