import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { returnTimeAgo, returnStatus, asyncDelay } from '../../../utility/helpers';
import { useSession } from '../../../utility/hooks';
import { post } from '../../../utility/api';
import Button from '../../shared/Button';
import Text from '../../shared/Text';
import Spinner from '../../shared/Spinner';
import {
    Logo,
    Logout,
    Parked,
    Charging,
    Phone
} from '../../../utility/icons';
import styles from '../../../utility/styles';

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`;

const Pill = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    height: 64px;
    border-radius: 32px;
    background-color: ${({ bgColor }) => bgColor || styles.colors.darkGray};
    margin-right: 32px;
`;

const StyledRow = styled.div`
    display: flex;
    align-items: center;
`;

const Divider = styled.div`
    width: 1px;
    height: 40px;
    background-color: ${styles.colors.lightGray};
    margin-right: 32px;
`;

const VertPill = styled.div`
    width: 8px;
    height: 24px;
    background-color: ${styles.colors.success};
    border-radius: 4px;
    margin-right: 16px;
`;

const renderIcon = status => {
    switch (status) {
        case 'Parked':
            return <Parked />
        case 'Charging':
            return <Charging />
        case 'Driving':
            return <Phone />
        default:
            return <Parked />
    }
}

/**
 * 
 * @param {{status: 'Parked'|'Charging'|'Driving'}} props
 * @returns 
 */
export default function Header({ status, booleans, lastFetched }) {

    const session_id = useSession();
    const enhancedStatus = returnStatus(status, booleans)
    const [pushSent, setPushSent] = React.useState('');

    const pushDrive = React.useCallback(async() => {
        setPushSent('loading');
        await post(`/dash/${session_id}/action/live_drive_push`);
        setPushSent('done');
        await asyncDelay(5000);
        setPushSent('')
    }, [session_id, setPushSent])

    return (
        <Row style={enhancedStatus === 'Driving' ? { margin: '20px 80px 32px 80px' } : { margin: '20px 20px 40px 20px' }}>
            <Col>
                <Wrapper>
                    <Button as='a' href='/' style={{ backgroundColor: 'transparent' }}>
                        <Logo />
                    </Button>
                    <StyledRow>
                        {enhancedStatus === 'Driving' ? (
                            <StyledRow>
                                <Button onClick={pushDrive} hideShadow style={{ backgroundColor: 'transparent' }}>
                                    <Pill bgColor='transparent'>
                                        {pushSent === 'loading' ? (
                                            <Spinner 
                                                color={styles.colors.lightGray}
                                                size={32}
                                            />
                                        ) : (
                                            <React.Fragment>
                                                {renderIcon(enhancedStatus)}
                                            </React.Fragment>
                                        )}
                                        <Text.Body style={{ color: styles.colors.lightGray }}>{pushSent === 'done' ? 'Notification Sent' : 'View on phone'}</Text.Body>
                                    </Pill>
                                </Button>
                                <Divider />
                                <VertPill />
                                <Text.Body variant='bold'>Updated approx. <span style={{color: styles.colors.success}}>{returnTimeAgo(lastFetched)}</span></Text.Body>
                            </StyledRow>
                        ) : (
                            <React.Fragment>
                                <Pill bgColor={status === 'Charging' ? styles.colors.chargingGreen : styles.colors.darkGray}>
                                    {renderIcon(enhancedStatus)}
                                    <Text.Heading type='h4' variant='bold' style={{ marginLeft: '8px' }}>{enhancedStatus}</Text.Heading>
                                </Pill>
                            </React.Fragment>
                        )}
                        <Button
                            style={{ backgroundColor: 'transparent' }}
                            as='a'
                            href={`/dash/${session_id}/logout`}
                        >
                            <Logout />
                        </Button>
                    </StyledRow>
                </Wrapper>
            </Col>
        </Row>
    )
}