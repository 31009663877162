import React from 'react';
import styled from 'styled-components';
import styles from '../../utility/styles';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShareScreenWrapper from '../shared/shareScreens/ShareScreenWrapper';

const StyledButton = styled(Button)`
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.white};
    background-color: ${styles.colors.blue};
    padding: 8px 12px;
    border-radius: 2px;
`;

const Post = styled.div`
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    overflow: hidden;
`;

const PostBody = styled.div`
    padding: 16px;
    background-color: ${styles.colors.darkGray};
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const BodyText = styled.div`
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.white};
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${styles.colors.white};
    margin-bottom: 4px;
`;

const MembersNum = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.lightGray};
    margin-bottom: 24px;
`;

export default function GroupScreen({ data, ratings }) {

    const membersNumText = data.num_members === 1 ? `${data.num_members} Member` : `${data.num_members} Members`;

    return (
        <ShareScreenWrapper {...{ ratings }}>
            <Container>
                <Row className='d-flex justify-content-center mt-4'>
                    <Col lg={6}>
                        <Post>
                            <Image fluid src={data.image_url} />
                            <PostBody>
                                <Title>{data.name}</Title>
                                <MembersNum>{membersNumText}</MembersNum>
                                <BodyText>{data.description}</BodyText>
                            </PostBody>
                        </Post>
                        <div style={{ margin: '24px auto', display: 'flex', justifyContent: 'center' }}>
                            <StyledButton href={`https://tezlabapp.com/app/applink/activity/group/${data.id}`} >Open in TezLab</StyledButton>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ShareScreenWrapper>
    )

}