import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Text from './Text';
import { chunkify } from '../../utility/helpers';
import styles from '../../utility/styles';


const Container = styled.div`
    display: flex;
    flex: 1;
    padding-bottom: 24px;
    border-bottom: 1px solid ${styles.colors.darkGray};
    margin-bottom: 24px;
`;

const LetterWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const LetterContainer = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background-color: ${styles.colors.darkGray};
`;

const StyledList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`


export default function FoundersRow({ list = [], letter }) {
    return (
        <Container>
            <LetterWrapper>
                <LetterContainer>
                    <Text.Heading type='h4' variant='bold'>{letter}</Text.Heading>
                </LetterContainer>
            </LetterWrapper>
            <Row className="d-flex flex-row justify-content-start align-items-start" style={{marginLeft: "2rem", flex: 10}}>
                {list.map((names, i) => {
                    if (names?.length){
                        return(
                            <Col xs={12} sm={6} md={3} key={`letter-${letter}-col-${i + 1}`} className='d-flex flex-column justify-content-center align-items-start'>
                                {names.map(nameObject => (
                                    <Text.Caption key={nameObject.name}>{nameObject.name_override ? nameObject.name_override : nameObject.name}</Text.Caption>
                                ))}
                            </Col>
                        )
                       
                    } else {
                        let nameObject = names;
                        
                        return(
                            <Col xs={12} sm={6} md={3} key={`letter-${letter}-col-${i + 1}`} className='d-flex flex-column justify-content-center align-items-start'>
                                <Text.Caption key={nameObject.name}>{nameObject.name_override ? nameObject.name_override : nameObject.name}</Text.Caption>
                            </Col>
                        )
                    }
                })}
            </Row>
        </Container>
    )
}