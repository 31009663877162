import { useReducer } from 'react'

const START_FETCHING = 'START_FETCHING'
const RECIEVE_DATA = 'RECIEVE_DATA'
const RECIEVE_ERROR = 'RECIEVE_ERROR'

const startFetching = () => ({
	type: START_FETCHING,
})

const recieveData = payload => ({
	type: RECIEVE_DATA,
	payload,
})

const recieveError = payload => ({
	type: RECIEVE_ERROR,
	payload,
})

/**
 *
 * @returns {[{error: string, isFetching: boolean, data: any}, {startFetching: () => void, recieveData: (payload: any) => void, recieveError: (payload: string) => void}]}
 */
export const useStandardState = () => {
	const initialState = {
		data: null,
		error: '',
		isFetching: false,
	}

	function reducer(state = initialState, { type, payload }) {
		switch (type) {
			case START_FETCHING:
				return { ...state, isFetching: true }
			case RECIEVE_DATA:
				return { error: '', data: payload, isFetching: false }
			case RECIEVE_ERROR:
				return { ...state, error: payload, isFetching: false }
			default:
				return state
		}
	}

	const [state, dispatch] = useReducer(reducer, initialState)

	return [
		state,
		{
			/**
			 *
			 * @returns {void}
			 */
			startFetching: () => dispatch(startFetching()),
			/**
			 *
			 * @param {any} payload
			 * @returns {void}
			 */
			recieveData: payload => dispatch(recieveData(payload)),
			/**
			 *
			 * @param {string} payload
			 * @returns {void}
			 */
			recieveError: payload => dispatch(recieveError(payload)),
		},
	]
}

/**
 *
 * @param {string} url
 * @param {{timeout?:number}&RequestInit} options
 * @returns {Promise<{data?: any, success: boolean, error?: any}>}
 */
export const get = async (url, options = {}) => {
	const { timeout = 15000 } = options
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)
	try {
		const response = await fetch(url, {
			method: 'GET',
			...options,
		})
		if (!response.ok) throw response.statusText ?? 'Generic Error Message'
		const data = await response.json()
		clearTimeout(id)
		// console.log('api get', { response, data })
		return { data, success: true }
	} catch (error) {
		clearTimeout(id)
		// console.log('api get', { error })
		return { error, success: false }
	}
}

/**
 *
 * @param {string} url
 * @param {object} [body]
 * @param {{timeout?:number}&RequestInit} options
 * @returns {Promise<{data?: any, success: boolean, error?: any}>}
 */
export const post = async (url, body = {}, options = {}) => {
	const { timeout = 15000 } = options
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)
	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...options?.headers,
			},
			body: JSON.stringify(body),
			...options,
		})
		if (!response.ok) throw response.statusText ?? 'Generic Error Message'
		const data = await response.json()
		clearTimeout(id)
		// console.log('api post', { response, data })
		return { data, success: true }
	} catch (error) {
		clearTimeout(id)
		// console.log('api post', { error })
		return { error, success: false }
	}
}

/**
 *
 * @param {string} url
 * @param {object} [body]
 * @param {{timeout?:number}&RequestInit} options
 * @returns {Promise<{data?: any, success: boolean, error?: any}>}
 */
export const patch = async (url, body = {}, options = {}) => {
	const { timeout = 15000 } = options
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)
	try {
		const response = await fetch(url, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				...options?.headers,
			},
			body: JSON.stringify(body),
			...options,
		})
		if (!response.ok) throw response.statusText ?? 'Generic Error Message'
		const data = await response.json()
		clearTimeout(id)
		// console.log('api patch', { response, data })
		return { data, success: true }
	} catch (error) {
		clearTimeout(id)
		// console.log('api patch', { error })
		return { error, success: false }
	}
}

/**
 *
 * @param {string} url
 * @param {{timeout?:number}&RequestInit} options
 * @returns {Promise<{data?: any, success: boolean, error?: any}>}
 */
export const del = async (url, options = {}) => {
	const { timeout = 15000 } = options
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)
	try {
		const response = await fetch(url, {
			method: 'DELETE',
			...options,
		})
		if (!response.ok) throw response.statusText ?? 'Generic Error Message'
		const data = await response.json()
		clearTimeout(id)
		// console.log('api delete', { response, data })
		return { data, success: true }
	} catch (error) {
		clearTimeout(id)
		// console.log('api del', { error })
		return { error, success: false }
	}
}

// gift / purchase new api

/**
 *
 * @param {string} url
 * @param {{timeout?:number}&RequestInit} options
 * @returns {Promise<{data?: any, success?: boolean, message?: string, error?:string, status?:number}>}
 */
export const apiGet = async (url, options = {}) => {
	const { timeout = 15000, ...rest } = options
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)
	try {
		const response = await fetch(url, {
			method: 'GET',
			...rest,
		})
		const json = await response.json()
		clearTimeout(id)
		if (!response.ok) {
			// return proper errors
			return {
				message: json?.message,
				error: response.statusText,
				status: response.status,
			}
		}
		return { data: json, success: true, status: response.status }
	} catch (error) {
		console.warn('apiGet error', { error })
		clearTimeout(id)
		return {
			message:
				typeof error === 'string'
					? error
					: typeof error?.message === 'string'
					? error.message
					: 'standard error',
		}
	}
}

/**
 *
 * @param {string} url
 * @param {object} [body]
 * @param {{timeout?:number, useJSONString?:boolean}&RequestInit} options
 * @returns {Promise<{data?: any, success?: boolean, message?: string, error?:string, status?:number}>}
 */
export const apiPost = async (url, body = {}, options = {}) => {
	const { timeout = 15000, headers, useJSONString = true, ...rest } = options
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)
	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...headers,
			},
			body: useJSONString ? JSON.stringify(body) : body,
			...rest,
		})
		const json = await response.json()
		clearTimeout(id)
		if (!response.ok) {
			// return proper errors
			return {
				message: json?.message,
				error: response.statusText,
				status: response.status,
			}
		}
		return { data: json, success: true, status: response.status }
	} catch (error) {
		console.warn('apiPost error', { error })
		clearTimeout(id)
		return {
			message:
				typeof error === 'string'
					? error
					: typeof error?.message === 'string'
					? error.message
					: 'standard error',
		}
	}
}
