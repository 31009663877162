import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import validator from 'validator';
import styled from 'styled-components';
import Text from '../shared/Text';
import { StyledFloatingLabel, StyledInput, ButtonWrapper, FlashWrapper } from '../shared/Password';
import ScreenWrapper from '../shared/ScreenWrapper';
import Button from '../shared/Button';
import { Warning } from '../../utility/icons';
import styles from '../../utility/styles';

const StyledContainer = styled(Container)`
    padding: 0 24px;
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 75px;
`;

const StyledLink = styled.a`
    color: ${styles.colors.lightGray};
    text-decoration: underline;
    text-align: center;
`;

export default function ForgotPassword() {

    const [email, setEmail] = React.useState('');
    const [flash, setFlash] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleEmailChange = React.useCallback(({ target: { value } }) => {
        setEmail(value)
    }, [setEmail])

    const submitEmail = React.useCallback(async () => {
        try {
            setLoading(true);
            setFlash('');
            setError('');
            const response = await fetch('/v2/password/request_reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email.trim() })
            })
            if (!response.ok) throw 'Error Submitting Email'
            const json = await response.json();
            if (json && json.success) {
                setFlash(json.message)
            } else {
                setError(json.message)
            }
            setLoading(false);
        } catch (error) {
            setError(typeof error === 'string' ? error : 'Error Submitting Email');
            setLoading(false);
        }
    }, [email, setError, setFlash, setLoading])

    return (
        <ScreenWrapper>
            <StyledContainer>
                <Row style={{ maxWidth: '500px' }}>
                    <Col>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <Warning />
                        </div>
                        <Text.Heading type='h2' variant='bold' style={{ textAlign: 'center', color: styles.colors.whiteGray }}>Password Reset</Text.Heading>
                        <Text.Body style={{ textAlign: 'center', color: styles.colors.whiteGray }}>Enter the email address on your TezLab account to request a password reset.</Text.Body>
                        <Form onSubmit={e=>e.preventDefault()}>
                            <StyledFloatingLabel
                                controlId='floatingEmailInput'
                                label='TezLab Account Email'
                            >
                                <StyledInput
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder='user@example.com'
                                    type='email'
                                />
                            </StyledFloatingLabel>
                        </Form>
                        <ButtonWrapper>
                            <Button 
                                onClick={submitEmail} 
                                style={{ display: 'flex', flex: 1, marginBottom: '24px', padding: '12px' }} 
                                disabled={!validator.isEmail(email)}
                                {...{ loading }}
                            >
                                Request Reset Link
                            </Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <Text.Caption style={{ color: styles.colors.lightGray }}>
                                Follow{' '}
                                <StyledLink href='https://www.tesla.com/user/password' target='_blank'>this link</StyledLink>
                                {' '}
                                to reset your Tesla password.
                            </Text.Caption>
                        </ButtonWrapper>
                        {Boolean(flash) && (
                            <FlashWrapper variant='success'>
                                <Text.Caption>{flash}</Text.Caption>
                            </FlashWrapper>
                        )}
                        {Boolean(error) && (
                            <FlashWrapper variant='error'>
                                <Text.Caption>{error}</Text.Caption>
                            </FlashWrapper>
                        )}
                    </Col>
                </Row>
            </StyledContainer>
        </ScreenWrapper>
    )
}