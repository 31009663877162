import React from 'react';
import styled from 'styled-components';
import Text from '../../shared/Text';
import styles from '../../../utility/styles';
import Button from '../../shared/Button';

const VersionSection = styled.div`
    transition: all 0.3s;
    @media only screen and (max-width: 768px) {
        margin-top: 12px
    }
`

const MyRow = styled.div`
    display: flex;
    justify-content: ${({ justify }) => justify || 'flex-start'};
    align-items: center;
    margin-bottom: ${({ margin }) => margin || '4px'};
`

const BarFront = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: ${styles.colors.white};
`

const BarBack = styled.div`
    position: relative;
    width: 100%;
    height: 8px;
    border-radius: 2px;
    margin-bottom: 4px;
    background-color: ${({ background }) => background};
    overflow: hidden;
`

const StyledButton = styled(Button)`
    align-self: center;
    margin: 0 auto;
    padding: 4px 8px;
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
`

const Bar = ({ fraction, background = styles.colors.white }) => (
    <BarBack {...{ background }} >
        <BarFront style={{ width: `${fraction * 100}%` }} />
    </BarBack>
)

export default function Locations({ locations = [] }) {
    const [isExpanded, setIsExpanded] = React.useState(false)
    const toggleExpand = () => setIsExpanded(!isExpanded)
    const filtered = isExpanded ? locations : locations.slice(0, 5)
    return (
        <VersionSection>
            <Text.Tiny variant='bold' color={styles.colors.lightGray}>Location</Text.Tiny>
            {!locations.length ? (
                <MyRow>
                    <Text.Caption variant='bold'>No Data</Text.Caption>
                </MyRow>
            ) : (
                <React.Fragment>
                    {filtered.map(({ country, state, title, pct }) => (
                        <MyRow justify='space-between' key={`${country}-${state}`}>
                            <MyRow style={{ flex: 1 }}>
                                <Text.Caption style={{ marginRight: '4px' }}><Text.Caption variant='bold' as='span'>{title}</Text.Caption> {pct}%</Text.Caption>
                            </MyRow>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <Bar fraction={pct / 100} background={styles.colors.darkGray} />
                            </div>
                        </MyRow>
                    ))}
                </React.Fragment>
            )}
            {locations.length > 5 && <StyledButton onClick={toggleExpand} variant='secondary'>{isExpanded ? 'Less' : 'More'} Locations</StyledButton>}
        </VersionSection>
    )
}