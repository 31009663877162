import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import styled from 'styled-components'
import Text from './Text'
import {
	Subscription,
	TeamLogo,
	LiveDrive,
	FoundersLogo,
	FaqLogo,
	AppLogo,
	Hamburger,
	LogoHoriz,
	LogoVert,
	MapIcon,
	SoftwareTracker,
} from '../../utility/icons'
import styles from '../../utility/styles'
import { isTesla } from '../../utility/helpers'

const activePaths = {
	app: ['app'],
	map: ['charger_map'],
	team: ['team'],
	founders: ['founders'],
	dash: ['dash'],
	subscribe: ['subscribe', 'subscription', 'gift'],
	faq: ['faq'],
	software: ['software-tracker'],
}

const StyledLink = styled(Text.NavLink)`
	margin: 12px 0;
	text-decoration: none;
	@media screen and ${styles.breakpoints.mobileL} {
		margin: 6px 0;
	}
`

const StyledNav = styled(Navbar)`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 125px;
	z-index: 1000;
	padding: 24px 56px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	background-color: ${styles.colors.background};
	&.solid-bg {
		background-color: ${styles.colors.background};
	}
	transition: top 0.2s, background-color 0.2s;
	@media screen and (max-width: 992px) {
		padding: 0 24px;
		flex-direction: row;
		width: 100vw;
		height: 72px;
	}
`

const StyledN = styled(Nav)`
	background-color: ${styles.colors.background};
	@media screen and (max-width: 992px) {
		padding: 0 24px;
		float: right;
		margin-right: -24px;
	}
`

const StyledLogoSmall = styled(LogoHoriz)`
	display: none;
	@media screen and (max-width: 992px) {
		display: block;
	}
`

const StyledLogoLarge = styled(LogoVert)`
	display: block;
	margin-bottom: 24px;
	@media screen and (max-width: 992px) {
		display: none;
	}
`

/**
 *
 * @param {{style:React.CSSProperties}} props
 * @returns
 */
export default function Header() {
	return (
		<React.Fragment>
			<StyledNav
				id='navbar'
				collapseOnSelect
				expand='lg'
			>
				<Nav.Link href='/'>
					<StyledLogoSmall />
					<StyledLogoLarge />
				</Nav.Link>
				<Navbar.Toggle
					style={{ marginLeft: 'auto' }}
					aria-controls='responsive-navbar-nav'
				>
					<Hamburger />
				</Navbar.Toggle>
				<Navbar.Collapse
					id='responsive-navbar-nav'
					className='align-items-start'
				>
					<StyledN className='d-flex flex-column'>
						{/* <StyledLink
                            href='/mission'
                            iconComponent={<MissionLogo fill={styles.colors.white} />}
                        >
                            Mission
                        </StyledLink> */}
						<StyledLink
							href='/app'
							activePaths={activePaths.app}
							iconComponent={
								<AppLogo
									activePaths={activePaths.app}
									fill={styles.colors.white}
								/>
							}
						>
							The App
						</StyledLink>
						{/* <StyledLink
                            href='/stats'
                            iconComponent={<StatsLogo fill={styles.colors.white} />}
                        >
                            Stats
                        </StyledLink> */}
						<StyledLink
							isSinglePage
							href='/map'
							activePaths={activePaths.map}
							iconComponent={
								<MapIcon
									activePaths={activePaths.map}
									fill={styles.colors.white}
								/>
							}
						>
							Charger Map
						</StyledLink>
						<StyledLink
							href='/team'
							activePaths={activePaths.team}
							iconComponent={
								<TeamLogo
									activePaths={activePaths.team}
									fill={styles.colors.white}
								/>
							}
						>
							Team
						</StyledLink>
						<StyledLink
							href='/subscribe'
							activePaths={activePaths.subscribe}
							iconComponent={
								<Subscription
									activePaths={activePaths.subscribe}
									fill={styles.colors.white}
								/>
							}
						>
							Subscribe
						</StyledLink>
						<StyledLink
							href='/founders'
							activePaths={activePaths.founders}
							iconComponent={
								<FoundersLogo
									activePaths={activePaths.founders}
									fill={styles.colors.white}
								/>
							}
						>
							Founders Series
						</StyledLink>
						{isTesla() && (
							<StyledLink
								href='/dash'
								activePaths={activePaths.dash}
								iconComponent={
									<LiveDrive
										activePaths={activePaths.dash}
										fill={styles.colors.white}
									/>
								}
							>
								Dashboard
							</StyledLink>
						)}
						<StyledLink
							href='/software-tracker/tesla'
							activePaths={activePaths.software}
							iconComponent={
								<SoftwareTracker
									activePaths={activePaths.software}
									fill={styles.colors.white}
								/>
							}
						>
							Software Tracker
						</StyledLink>
						<StyledLink
							href='https://support.tezlabapp.com'
							activePaths={activePaths.faq}
							iconComponent={
								<FaqLogo
									activePaths={activePaths.faq}
									fill={styles.colors.white}
								/>
							}
						>
							Support
						</StyledLink>
					</StyledN>
				</Navbar.Collapse>
			</StyledNav>
		</React.Fragment>
	)
}
