import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import styles from '../../../utility/styles';

// assets
const founders_icon = 'https://static-content.tezlabapp.com/web-images/tezlab_founders_series_2x.png';
const pro_icon = 'https://static-content.tezlabapp.com/web-images/pro/ic_pro.png';

const ZapWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const Name = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.white}
`;

const Model = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.lightGray};
`;

const StyledButton = styled(Button)`
    font-family: ${styles.fonts.nunito};
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.white};
    padding: 8px 12px;
    border-radius: 50px;
    &:hover {
        color: ${styles.colors.whiteGray};
    }
    @media (max-width: 760px) {
        margin-bottom: 8px;
        display: ${({ type }) => type === 'zap_post' ? 'none' : 'flex'};
    }
`;

const NameRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 760px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const UserName = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0 4px 4px 0;
`;

const ModelName = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.lightGray};
`;

const StyledRow = styled(Row)`
    display: ${({ type }) => type === 'zap_post' ? 'none' : 'flex'};
    margin: 0 auto 20px auto;
    @media (max-width: 760px) {
        display: flex;
        margin: 0 20px 20px 20px;
    }
`;

const ZapAvatar = ({ user, children, type, href }) => {

    if (type !== 'zap_post') return (
        <React.Fragment>{children}</React.Fragment>
    )

    if (!user) return null;

    return (
        <ZapWrapper>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', marginBottom: 16 }}>
                <Image src={user.image} roundedCircle style={{ width: '32px', height: '32px', marginRight: '8px' }} />
                <div style={{ flexDirection: 'column' }}>
                    <NameRow>
                        <Name>{user.name}</Name>
                        {/* <Image src={pro_icon} style={{ height: 16, width: 16, marginLeft: 8, color: styles.colors.blue }} /> */}
                    </NameRow>
                    <Model>{user.model_name}</Model>
                </div>
            </div>
            {children}
            <ButtonRow>
                <StyledButton style={{ marginRight: 16, backgroundColor: styles.colors.blue }} {...{ href, type }}>View on TezLab</StyledButton>
                <StyledButton style={{ backgroundColor: styles.colors.background, borderColor: styles.colors.background }} href={user.share_url} {...{ type }}>Follow Me</StyledButton>
            </ButtonRow>
        </ZapWrapper>
    )
}

const SocialAvatar = ({ type, user, style = {} }) => {

    if (!user) return null;

    return (
        <StyledRow {...{ type }} style={{ marginBottom: 20, ...style }}>
            <Col className='d-flex justify-content-start'>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <Image src={user.image} roundedCircle style={{ width: '32px', height: '32px', marginRight: '8px' }} />
                    <div style={{ flexDirection: 'column' }}>
                        <NameRow>
                            <Name>{user.name}</Name>
                            {/* <Image src={pro_icon} style={{ height: 16, width: 16, marginLeft: 8, color: styles.colors.blue }} /> */}
                        </NameRow>
                        <Model>{user.model_name}</Model>
                    </div>
                </div>
            </Col>
            <Col className='d-flex justify-content-end align-items-center'>
                <StyledButton style={{ backgroundColor: styles.colors.blue }} href={user.share_url}>Follow me</StyledButton>
            </Col>
        </StyledRow>
    )
}

const UserAvatar = ({ name, image, modelName, isPro, isFounder, style = {} }) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...style }}>
            {Boolean(image) && <Image roundedCircle width={96} src={image} />}
            <NameRow style={{ alignItems: 'center' }}>
                <UserName>{name}</UserName>
                {isPro && !isFounder && <Image width={24} height={24} src={pro_icon} />}
                {isFounder && <Image width={24} height={24} src={founders_icon} />}
            </NameRow>
            <ModelName>{modelName}</ModelName>
        </div>
    )
}

export default {
    ZapAvatar,
    SocialAvatar,
    UserAvatar
}