import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import styles from '../../utility/styles'
import { FloatingLabel, FormControl, FormControlProps } from 'react-bootstrap'

const StyledFloatingGroupLabel = styled(FloatingLabel)`
	& > label {
		z-index: 100;
		color: ${styles.colors.lightGray};
        font-size: 16px;
        font-weight: normal;
        padding: 12px;
	}
    &.form-floating > .form-control {
        padding: 24px 12px
    }
	&.form-floating > .form-control:not(:placeholder-shown) {
		padding: 32px 12px 24px 12px
	}
	&.form-floating > .form-control:focus {
		padding: 32px 12px 24px 12px
	}
	&.form-floating > .form-control:focus ~ label,
	&.form-floating > .form-control:not(:placeholder-shown) ~ label {
		opacity: 0.65;
		transform: scale(0.8) translateY(-1rem) translateX(0.15rem);
	}
`

const StyledInput = styled(FormControl)`
	background: ${styles.colors.darkGray};
	border-radius: 1px;
	border: none;
	color: ${styles.colors.white};
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	&:focus {
		background: ${styles.colors.darkGray};
		color: ${styles.colors.white};
	}
	&::placeholder {
		color: ${styles.colors.lightGray};
		font-weight: lighter;
	}
`

/**
 *
 * @param {{id:string,label:string, inputStyle:CSSProperties}&FormControlProps} props
 * @returns
 */
export default function Input({ id, label, style, inputStyle, ...props }) {
	return (
		<StyledFloatingGroupLabel controlId={id} {...{ label, style }}>
			<StyledInput
				{...{ id }}
				placeholder={label ? label : props.placeholder}
				style={inputStyle}
				{...props}
			/>
		</StyledFloatingGroupLabel>
	)
}
