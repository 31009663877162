import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import styles from '../../../utility/styles';

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${styles.colors.background};
`;

const ContentContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 100px 0 50px 0;
`;

export default function ShareScreenWrapper({ children, ratings }) {
    return (
        <Wrapper>
            <ContentContainer>
                {children}
            </ContentContainer>
            <Footer {...{ ratings }} />
        </Wrapper>
    )
}