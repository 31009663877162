import React from 'react';
import Image from 'react-bootstrap/Image';
import styled from 'styled-components';
import play_alternate from '../../../../assets/images/play_alternate.png';
import post_alert_icon from '../../../../assets/images/post_alert_icon.png';
import styles from '../../../utility/styles';

const Video = styled.video`
    width: 100%;
    height: 100%;
    background-color: ${styles.colors.darkGray};
    color: ${styles.colors.white};
`;

const VideoButton = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    overflow: hidden;
    display: flex;
`;

const VideoIcon = styled(Image)`
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    width: 40px;
    height: 40px;
`;

const NoMapWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: ${({ media }) => `url(${media})`};
    height: 170px;
    background-size: cover;
`;

const Gradient = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${styles.colors.background};
    opacity: 0.7;
`;

const InfoBox = styled.div`
    background-color: ${styles.colors.background};
    padding: 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
`;

const NoMapText = styled.div`
    font-size: 13px;
    line-height: 16px;
    margin-left: 16px;
`;

function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

/**
 * 
 * @param {{media: any[], hideMedia?:boolean}} props 
 * @returns 
 */
function ZapPost({ media, hideMedia = false }) {

    const [isPlaying, setIsPlaying] = React.useState(false);
    const videoRef = React.useRef();

    const playVideo = () => {
        if (videoRef.current) return videoRef.current.play();
        window.alert('no ref')
    }

    const pauseVideo = () => {
        if (videoRef.current) return videoRef.current.pause();
        window.alert('no ref')
    }

    React.useEffect(() => {
        if (isPlaying) {
            playVideo();
        } else {
            pauseVideo();
        }
    }, [isPlaying])

    const isIOS = React.useMemo(() => iOS())

    if (hideMedia || !media || (Array.isArray(media) && !media.length)) return null;

    return (
        <VideoButton onClick={() => setIsPlaying(bool => !bool)} >
            <Video loop width='100%' height='100%' playsInline={true} preload='auto' controls={isIOS} ref={videoRef}>
                <source src={media[0].url} type='video/mp4' />
                This Video Format is not supported in your Browser
            </Video>
            {!isPlaying && <VideoIcon src={play_alternate} />}
        </VideoButton>
    )
}

/**
 * 
 * @param {{media: any[], hideMedia?:boolean}} props 
 * @returns 
 */
function MediaPost({ media, hideMedia = false }) {

    if (hideMedia || !media || (Array.isArray(media) && !media.length)) return null;

    return (
        <React.Fragment>
            {Array.isArray(media) && <Image fluid src={media[0].url} style={{ height: 'auto' }} />}
            {!Array.isArray(media) && (
                <NoMapWrapper {...{ media }}>
                    <Gradient />
                    <InfoBox>
                        <Image src={post_alert_icon} style={{ width: 17 }} />
                        <NoMapText>This user has shared this trip without map data.</NoMapText>
                    </InfoBox>
                </NoMapWrapper>
            )}
        </React.Fragment>
    )
}

export default {
    MediaPost,
    ZapPost
}