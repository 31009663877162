import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { dissectName } from '../../utility/helpers'
import styled from 'styled-components'
import styles from '../../utility/styles'
import { FoundersIcon, ProIconBlue } from '../../utility/icons'
import Text from '../shared/Text'

const AvatarIcon = ({ subscription }) => {
	if (!subscription?.type || subscription?.type === 'standard') return false
	if (subscription.type === 'founders_series')
		return (
			<FoundersIcon
				width='20'
				height='20'
				style={{ marginLeft: '8px' }}
			/>
		)

	return <ProIconBlue width='20' height='20' style={{ marginLeft: '8px' }} />
}

const Wrapper = styled.div`
	background-color: ${styles.colors.background};
	padding: 48px;
`

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 48px 0;
`

const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 4px 16px 4px 4px;
	border-radius: 20px;
	background-color: ${styles.colors.darkGray};
	margin-bottom: 48px;
`

const AvatarBacker = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: content-box;
	border: 2px solid
		${({ show }) => (show ? 'transparent' : styles.colors.blue)};
	height: 28px;
	width: 28px;
	border-radius: 16px;
	margin-right: 8px;
	background-color: ${styles.colors.darkGray};
`

const Avatar = styled.img`
	position: absolute;
	width: 32px;
	height: 32px;
	background-color: ${styles.colors.darkGray};
`

const PRO_TEXT = 'Thanks for joining TezLab Pro! '
const FOUNDERS_TEXT = 'Thanks for becoming part of our Founders Series program!'

export default function SubscriptionSuccess({ data }) {
	return (
		<Wrapper>
			<Container>
				<Row>
					<Col>
						<Text.Heading
							color={styles.colors.whiteGray}
							variant='bold'
							style={{ textAlign: 'center' }}
							mobileL='h3'
							allowWidows
						>
							Membership Confirmed
						</Text.Heading>
						<TextWrapper>
							<AvatarWrapper>
								<AvatarBacker show={Boolean(data.user?.avatar)}>
									<Text.Caption
										color={styles.colors.blue}
										variant='bold'
									>
										{dissectName(data.user?.name)}
									</Text.Caption>
									{Boolean(data.user?.avatar) && (
										<Avatar src={data.user?.avatar} />
									)}
								</AvatarBacker>
								<Text.Body
									color={styles.colors.whiteGray}
									variant='bold'
								>
									{data.user?.name}
								</Text.Body>
								<AvatarIcon
									subscription={data.active_subscription}
								/>
							</AvatarWrapper>
							<Text.Heading
								type='h5'
								variant='bold'
								color={styles.colors.whiteGray}
								style={{ marginBottom: '12px', textAlign: 'center' }}
							>
								
								{data.active_subscription?.type === 'founders_series' ? FOUNDERS_TEXT : PRO_TEXT}
								{/*{data.active_subscription?.title}! */}
							</Text.Heading>
							<Text.Body style={{ textAlign: 'center' }}>
								Your new membership status will be reflected in the app momentarily.
							</Text.Body>
							<Text.Link href={data.manage_url} style={{marginTop: '12px'}}>
								Manage Subscription
							</Text.Link>
						</TextWrapper>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	)
}
