import React from 'react';
import Charge from './Charge';
import LiveDrive from './LiveDrive';
import Parked from './Parked';
import { returnStatus } from '../../../utility/helpers';

/**
 * 
 * @param {{status: 'Driving'|'Charging'|'Parked', data:any}} props
 */
export default function Body({ status, booleans, data }) {
    const enhancedStatus = returnStatus(status, booleans)
    switch (enhancedStatus) {
        case 'Charging':
            return <Charge {...{ data }} />
        case 'Driving':
            return <LiveDrive {...{ data }} />
        default:
            return <Parked {...{ data }} />
    }
}