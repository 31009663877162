// array helpsrs
import moment from "moment-timezone"
import validator from 'validator'

/**
 * 
 * @param {array} arr
 * @param {number} [chunkSize]
 * @param {array} [cache]
 * @returns 
 */
export const chunk = (arr, chunkSize = 1, cache = []) => {
    const tmp = [...arr]
    if (chunkSize <= 0) return cache
    while (tmp.length) cache.push(tmp.splice(0, chunkSize))
    return cache
}

/**
 * 
 * @param {array} arr 
 * @param {number} rows 
 */
export const chunkIntoRows = (arr, rows) => {
    const len = arr.length
    const chunkSize = len % rows
    return chunk(arr, chunkSize)
}

/**
 * 
 * @param {array} a 
 * @param {number} n 
 * @param {boolean} balanced 
 * @returns 
 */
export const chunkify = (a, n, balanced) => {

    if (n < 2) return [a];

    let len = a.length,
        out = [],
        i = 0,
        size;

    if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
            out.push(a.slice(i, i += size));
        }
    }

    else if (balanced) {
        while (i < len) {
            size = Math.ceil((len - i) / n--);
            out.push(a.slice(i, i += size));
        }
    }

    else {

        n--;
        size = Math.floor(len / n);
        if (len % size === 0)
            size--;
        while (i < size * n) {
            out.push(a.slice(i, i += size));
        }
        out.push(a.slice(size * n));

    }

    return out;
}

export const asyncDelay = async delayMs => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, delayMs);
    })
}

/**
 * Check if the user agent contains the string "tesla"
 * @returns A boolean value.
 */
export const isTesla = () => {
    return window.screen.width >= 1200 && ('ontouchstart' in window)
}

/**
 * 
 * @param {number} ratings 
 * @param {number} outOf
 */
export const sliceIt = (ratings, outOf = 5) => {
    try {
        const arr = Array.from(Array(Math.floor(ratings))).map(el => 1) // fill an array with ones based on the number of "full" stars
        const diff = ratings - Math.floor(ratings); // see what the value is for the next star (if ratings = 4.5 it will be 0.5)
        if (diff > 0) arr.push(Number(diff.toFixed(1)))
        while (arr.length < outOf) arr.push(0)
        return arr
    } catch (error) {
        return Array.from(Array(outOf)).map(el => 0)
    }
}

/**
 * 
 * @param {number} ts 
 * @param {{timeDiff:number, replacementText:string, prefix:string, timezone?:string}} [options]
 * @returns {string} time string
 */
export const returnTimeString = (ts, options = {}) => {
    const mom = moment(ts*1000)
    if (moment.isMoment(mom)) {
        if (options.timezone) mom.tz(options.timezone)
        if (mom.diff(moment()) <= options.timeDiff) return options.replacementText
        return `${options.prefix}${mom.format('h:mm a')}`
    }
    return ''
}

/**
 * 
 * @param {Date|string} date 
 * @param {{format?:string}} options 
 * @returns 
 */
export const formatDate = (date, options = {}) => {
	try {
		const { format = 'short' } = options
		return new Intl.DateTimeFormat(navigator.language, {
			dateStyle: format,
		}).format(new Date(date))
	} catch (error) {
		return date
	}
}

export const returnTimeAgo = ts => {
    const mom = moment(ts*1000)
    if (moment.isMoment(mom)) return mom.fromNow()
    return ''
}

/**
 * 
 * @param {'Driving'|'Charging'|'Parked'} status 
 * @param {{is_driving:boolean, is_charging:boolean}} booleans 
 */
export const returnStatus = (status, booleans = {}) => {
    const { is_charging, is_driving } = booleans;
    if (is_driving || status === 'Driving') return 'Driving'
    if (is_charging || status === 'Charging') return 'Charging'
    return status
}

export const isNullOrUndefined = thing => thing === null || thing === undefined

export const capitalizeWord = word => {
    if (typeof word !== 'string') return ''
    return word.split('').map((letter, i) => i === 0 ? letter.toUpperCase() : letter).join('');
}

export const dissectEmail = email => {
	try {
		if (typeof email !== 'string' || !validator.isEmail(email)) return ''
		const [first, second] = email.split('@')
		return `${first[0]}...@${second}`
	} catch (error) {
		return ''
	}
}

export const dissectName = name => {
    try {
        if (!name || typeof name !== 'string') return 'TU'
        const initials = name.split(' ').filter((_,i, a) => i === 0 || i === a.length - 1).map(word => word[0]).join('')
        return initials
    } catch (error) {
        return 'TU'
    }
}

/**
 * It takes an object and returns a new object with only the non-null and non-undefined values
 * @param {*} [data] - The data to be sanitized.
 * @returns An object with all the keys and values that are not null or undefined.
 */
export const sanitizeData = (data = {}) => {
    const sanitized = {}
    if (typeof data !== 'object' || Array.isArray(data)) throw new Error('data needs to be an object')
    for (const [key, value] of Object.entries(data)) {
        if (!isNullOrUndefined(value)) sanitized[key] = value
    }
    return sanitized
}

export const compareStrings = (...args) => {
    if (args.length < 2) return false
    try {
        const newArr = args.map(arg => arg.toLowerCase())
        return newArr.every((arg, i, arr) => i === 0 || arg === arr[i - 1])
    } catch (error) {
        return false
    }
}
