import React, { ButtonHTMLAttributes } from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import styles from '../../utility/styles'

const colors = {
	primary: styles.colors.blue,
	secondary: styles.colors.darkGray,
	success: styles.colors.green,
	disabled: styles.colors.mediumGray,
	secondary_alt: styles.colors.background,
}

const DefaultButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	box-shadow: ${({ as, hideShadow }) =>
		as !== 'button' || hideShadow
			? 'none'
			: '4.5px 6px 12px rgba(0, 0, 0, 0.16)'};
	background-color: ${({ variant }) => colors[variant]};
	border: none;
	border-radius: 50px;
	font-family: ${styles.fonts.nunito};
	font-weight: 800;
	color: ${({ color, disabled }) =>
		typeof color === 'string'
			? color
			: disabled
			? styles.colors.lightGray
			: styles.colors.white};
`

/**
 *
 * @param {{variant:'primary'|'secondary', spinnerVariant?:string, loading?:boolean, success?:boolean, as?:string, hideShadow?:boolean}&ButtonHTMLAttributes} props
 */
export default function Button({
	variant = 'primary',
	children,
	loading,
	success,
	disabled,
	hideShadow = false,
	spinnerVariant,
	pill = false,
	...props
}) {
	return (
		<DefaultButton
			variant={success ? 'success' : disabled ? 'disabled' : variant}
			{...{ disabled, hideShadow, pill }}
			{...props}
		>
			{loading ? (
				<Spinner
					as='span'
					animation='border'
					variant={spinnerVariant ? spinnerVariant : variant}
					role='status'
					aria-hidden='true'
				/>
			) : (
				<React.Fragment>{children}</React.Fragment>
			)}
		</DefaultButton>
	)
}
