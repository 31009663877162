import React from 'react';
import styled from 'styled-components';
import { isNullOrUndefined } from '../../utility/helpers';
import styles from '../../utility/styles';

const gradient = left =>
    isNullOrUndefined(left) ?
    '' :
    left ?
    'linear-gradient(90deg, #161C28 0.2%, rgba(22, 28, 40, 0) 99.8%), linear-gradient(91.14deg, #000000 4.68%, rgba(0, 0, 0, 0) 56.22%), ' :
    'linear-gradient(90deg, #000000 0%, rgba(22, 28, 40, 0) 0.01%, #161C28 100%), linear-gradient(268.56deg, rgba(0, 0, 0, 0.5) 1.02%, rgba(0, 0, 0, 0) 82.1%), '

const Image = styled.div`
    width: 100vw;
    max-width: 1600px;
    margin: auto;
    height: ${({ ratios, ratio }) => ratio ? `${1 / ratio * 100}vw` : `${1 / ratios.large * 100}vw`};
    background: ${({ srcs, src, style = {} }) => style.background ? '' : src ? `url(${src})` : `url(${srcs.large})`};
    background-size: cover;
    background-position: center;
    position: relative;
    @media only screen and ${styles.breakpoints.massive} {
        border-radius: ${({rounded}) => rounded ? rounded : '0'};
        background: ${({ srcs, src, left, style = {} }) => style.background ? '' : src ? `url(${src})` : `${gradient(left)}url(${srcs.large})`};
        background-size: cover;
        background-position: center;
        height: ${({ ratios, ratio }) => ratio ? `${1 / ratio * 1600}px` : `${1 / ratios.large * 1600}px`};
    }
    @media only screen and ${styles.breakpoints.mobileL} {
        height: ${({ ratios, ratio }) => ratio ? `${1 / ratio * 100}vw` : ratios.small ? `${1 / ratios.small * 100}vw` : `${1 / ratios.large * 100}vw`};
        background: ${({ srcs, src, style = {} }) => style.background ? '' : src ? `url(${src})` : `url(${srcs.small ? srcs.small : srcs.large})`};
        background-size: cover;
    }
`;

const StandardContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 79px 137px;
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 79px 56px;
    @media only screen and ${styles.breakpoints.mobileL} {
        display: none !important;
    }
`;

const Footer = styled.div`
    display: none;
    @media only screen and ${styles.breakpoints.mobileL} {
        display: flex !important;
        justify-content: center;
        transform: ${({ offset }) => offset ? `translateY(${-offset - 35}px)` : 'translateY(-35px)'};
    }
`;

/**
 * 
 * @param {{children:any, srcs?:{large:string,small:string}, ratios?:{large:number,small:number}, style:React.CSSProperties, offset?:number, containerStyle?:React.CSSProperties, left?:boolean rounded?:string}&React.ImgHTMLAttributes<HTMLImageElement>} props 
 * @returns 
 */
export default function HeroImage({ children, src, ratio, renderFooter, srcs = {}, ratios = {}, style, offset, containerStyle, left, rounded, ...props }) {

    if (src && ratio) {
        return (
            <>
                <Image {...{ src, ratio, style, rounded, left }} {...props}>
                    <StandardContainer style={containerStyle}>
                        {children}
                    </StandardContainer>
                </Image>
                <Footer {...{ offset }}>
                    {typeof renderFooter === 'function' && renderFooter()}
                </Footer>
            </>
        )
    }

    return (
        <>
            <Image {...{ srcs, ratios, style, rounded, left }} {...props}>
                <Container style={containerStyle}>
                    {children}
                </Container>
            </Image>
            <Footer {...{ offset }}>
                {children}
            </Footer>
        </>
    )
}