import React from 'react';
import styled from 'styled-components';
import styles from '../../utility/styles';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Avatar from '../shared/shareScreens/Avatar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShareScreenWrapper from '../shared/shareScreens/ShareScreenWrapper';

const StyledButton = styled(Button)`
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.white};
    background-color: ${styles.colors.blue};
    padding: 8px 12px;
    border-radius: 2px;
`;

const Post = styled.div`
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    overflow: hidden;
`;

const PostBody = styled.div`
    padding: 32px;
    background-color: ${styles.colors.darkGray};
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const StatText = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${styles.colors.white};
`;

const StatTitle = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${styles.colors.white};
`;

const LargeStatText = styled.div`
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: ${styles.colors.white};
`;

const LargeStatTitle = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.lightGray};
`;

const VerticalDivider = styled.div`
    height: 49px;
    width: 1;
    border-left-style: solid;
    border-left-width: 2px;
    border-left-color: ${styles.colors.background};
    margin: 0 24px;
`;

const StatRow = styled.div`
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const StatCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1
`;

export default function UserScreen({ data, ratings }) {

    const { stats, header_image } = data;

    return (
        <ShareScreenWrapper {...{ ratings }}>
            <Container>
                <Row className='d-flex justify-content-center mt-4'>
                    <Col lg={6}>
                        <Post>
                            {Boolean(header_image) && <Image fluid src={header_image} />}
                            <PostBody>
                                <Avatar.UserAvatar name={data.name} modelName={data.model_name} isFounder={data.founders_series} isPro={data.pro_user} image={data.image} />
                                <StatRow style={{ justifyContent: 'space-evenly' }}>
                                    <StatCol>
                                        <LargeStatText>{stats.efficiency_string}</LargeStatText>
                                        <LargeStatTitle>Efficiency</LargeStatTitle>
                                    </StatCol>
                                    <StatCol>
                                        <VerticalDivider />
                                    </StatCol>
                                    <StatCol>
                                        <LargeStatText>{stats.avg_speed_string}</LargeStatText>
                                        <LargeStatTitle>Avg Speed</LargeStatTitle>
                                    </StatCol>
                                </StatRow>
                                {stats.stats.map((stat, i) => (
                                    <StatRow key={`stat-${i + 1}`} style={{ borderTop: i === 0 ? `1px solid ${styles.colors.background}` : 'none', borderBottom: `1px solid ${styles.colors.background}` }}>
                                        <StatTitle>{stat.title}</StatTitle>
                                        <StatText>{stat.stat || 'N/A'}</StatText>
                                    </StatRow>
                                ))}
                            </PostBody>
                        </Post>
                        <div style={{ margin: '24px auto', display: 'flex', justifyContent: 'center' }}>
                            <StyledButton href={`${data.applink}`} >Follow me on TezLab</StyledButton>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ShareScreenWrapper>
    )

}