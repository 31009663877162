import { FloatingLabel, FormControl } from "react-bootstrap";
import styled from "styled-components";
import styles from '../../utility/styles';

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const StyledFloatingLabel = styled(FloatingLabel)`
    margin: 24px 0;
    &>label {
        color: ${styles.colors.lightGray};
        padding: 12px;
    }
    &.form-floating>.form-control:focus~label, &.form-floating>.form-control:not(:placeholder-shown)~label, &.form-floating>.form-select~label {
        opacity: .65;
        transform: scale(.8) translateY(-1.05rem) translateX(0.15rem);
    }
`;

export const StyledFloatingGroupLabel = styled(FloatingLabel)`
    &>label {
        z-index: 100;
        color: ${styles.colors.lightGray};
    }
    &.form-floating>.form-control:focus~label, &.form-floating>.form-control:not(:placeholder-shown)~label {
        opacity: .65;
        transform: scale(.8) translateY(-1.05rem) translateX(0.15rem);
    }
`;

export const StyledInput = styled(FormControl)`
    background: ${styles.colors.darkGray};
    border-radius: 1px;
    border: none;
    color: ${styles.colors.white};
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    padding: 24px 12px !important;
    &:focus {
        background: ${styles.colors.darkGray};
        color: ${styles.colors.white};
    }
    &::placeholder {
        color: ${styles.colors.lightGray};
        font-weight: lighter;
    }
`;

export const FlashWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    background: ${({variant}) => styles.functions.opacityModifier(styles.colors[variant], 16)};
    border: 1px solid ${({variant}) => styles.colors[variant]};
    border-radius: 2px;
    padding: 16px 24px;
    margin-bottom: 16px;
`;