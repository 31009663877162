import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Text from '../shared/Text';
import ScreenWrapper from '../shared/ScreenWrapper';
import FoundersRow from '../shared/FoundersRow';
import Button from '../shared/Button';
import { TreesSmall } from '../../utility/icons';
import styles from '../../utility/styles';

const StyledContainer = styled(Container)`
    padding: 0 24px;
    margin-top: 72px;
    margin-bottom: 72px;
`;

const TreeInfoWrapper = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
`;

const TreeIconWrapper = styled.div`
    padding: 0 24px;
    align-self: stretch;
    background-color: ${styles.colors.chargingGreen};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TreeTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    background-color: ${styles.functions.opacityModifier(styles.colors.chargingGreen, 16)};
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-bottom: 40px;
`;

const TitleText = styled(Text.Huge)`
    max-width: 90%;
    margin: auto;
    text-align: center;
    margin-bottom: 40px;
`;

/**
 * 
 * @param {{trees:number, list:any[]}} param0 
 * @returns 
 */
export default function FoundersList({ trees, list }) {
    const allRows = React.useMemo(() => {
        const rows = Object.entries(list).filter(row => row[0] !== 'others');
        const shrug = Object.entries(list).filter(row => row[0] === 'others');
        const all = Array.isArray(shrug) && Array.isArray(shrug[0][1]) && shrug[0][1].length ? [...rows, ...shrug] : rows
        return all
    }, [list])

    return (
        <ScreenWrapper>
            <StyledContainer>
                <TitleText type='mega' variant='bold' mobileL='h2'>Thank you to each of our Founders Series Members</TitleText>
                <TreeInfoWrapper>
                    <TreeIconWrapper>
                        <TreesSmall />
                    </TreeIconWrapper>
                    <TreeTextWrapper>
                        <Text.Heading type='h5'><span style={{ fontWeight: 800 }}>{trees.toLocaleString()} trees planted</span> by the TezLab community.</Text.Heading>
                    </TreeTextWrapper>
                </TreeInfoWrapper>
                {allRows.map(row => (
                    <FoundersRow list={row[1]} letter={row[0] == "others" ? "🤷" : row[0]} />
                ))}
                <ButtonWrapper>
                    <Button as='a' href='/founders'>
                        <Text.Body variant='bold'>Explore Founders Series</Text.Body>
                    </Button>
                </ButtonWrapper>
            </StyledContainer>
        </ScreenWrapper>
    )
}