import React from 'react';
import styled from 'styled-components';
import { CircularProgressbarWithChildren, buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import Text from '../../shared/Text';
import gs from '../../../utility/styles';

const internalBuildStyles = pathColor => buildStyles({
    strokeLinecap: 'round',
    pathTransitionDuration: 0.67,
    trailColor: 'transparent',
    pathColor
})

const styles = {root: { position: 'absolute' }, ...buildStyles({
    rotation: 0.75,
    strokeLinecap: 'round',
    pathTransitionDuration: 0.67,
    trailColor: gs.colors.darkGray
})}

const HorizontalWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const VerticalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ProgressWrapper = styled.div`
    height: 120px;
    width: 120px;
    margin-bottom: 24px
`;

const HorizontalProgressWrapper = styled.div`
    height: 120px;
    width: 120px;
    margin-right: 24px;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ isHorizontal }) => isHorizontal ? 'flex-start' : 'center'};
`;

const Val = styled(Text.Huge)`
    color: ${({ color }) => color};
`;

const Title = styled(Text.Heading)`
    color: ${gs.colors.lightGray};
`;

const Horizontal = ({ percent, text, title, Icon, colorFunc, isGray = false }) => {

    const stroke = React.useMemo(() => isGray ? gs.colors.lightGray : typeof colorFunc === 'function' ? colorFunc(percent) : gs.functions.returnBatteryColor(percent), [percent, colorFunc, isGray])

    return (
        <HorizontalWrapper>
            <HorizontalProgressWrapper>
                <CircularProgressbarWithChildren
                    value={percent}
                    circleRatio={0.67}
                    strokeWidth={12}
                    styles={{
                        root: {},
                        path: {
                            stroke,
                            strokeLinecap: 'round',
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.67turn)',
                            transformOrigin: 'center center',
                        },
                        trail: {
                            stroke: gs.colors.darkGray,
                            strokeWidth: 6,
                            strokeLinecap: 'round',
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.67turn)',
                            transformOrigin: 'center center',
                        }
                    }}
                >
                    <Icon />
                </CircularProgressbarWithChildren>
            </HorizontalProgressWrapper>
            <Col isHorizontal>
                <Val type='ultra' variant='bold' color={stroke}>{text}</Val>
                <Title type='h5' variant='bold'>{title}</Title>
            </Col>
        </HorizontalWrapper>
    )
}

const Vertical = ({ percent, text, title, Icon, isCharging = false, isGray = false }) => {

    const stroke = React.useMemo(() => isGray ? gs.colors.lightGray : isCharging ? gs.colors.chargingGreen : gs.functions.returnBatteryColor(percent), [percent, isCharging, isGray])

    return (
        <VerticalWrapper>
            <ProgressWrapper>
                <CircularProgressbarWithChildren
                    value={percent}
                    circleRatio={0.67}
                    strokeWidth={12}
                    styles={{
                        root: {},
                        path: {
                            stroke,
                            strokeLinecap: 'round',
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.67turn)',
                            transformOrigin: 'center center',
                        },
                        trail: {
                            stroke: gs.colors.darkGray,
                            strokeWidth: 6,
                            strokeLinecap: 'round',
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.67turn)',
                            transformOrigin: 'center center',
                        }
                    }}
                >
                    <Icon />
                </CircularProgressbarWithChildren>
            </ProgressWrapper>
            <Col isHorizontal={false}>
                <Val type='ultra' variant='bold' color={stroke}>{text}</Val>
                <Title type='h5' variant='bold'>{title}</Title>
            </Col>
        </VerticalWrapper>
    )
}

export default {
    Horizontal,
    Vertical
}