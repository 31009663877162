import React, { useMemo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import ScreenWrapper from '../../shared/ScreenWrapper';
import Text from '../../shared/Text';
import Button from '../../shared/Button';
import SubscriptionItem from './SubscriptionItem';
import styles from '../../../utility/styles';
import { post } from '../../../utility/api';

const StyledContainer = styled(Container)`
    margin-top: 80px;
`

const Heading = styled(Text.Heading)`
    text-align: center;
    margin-bottom: 32px;
`

const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    flex: 1;
`

const CenteredDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
`

const StyledButton = styled(Button)`
    background-color: ${({ buttonBackground }) => buttonBackground};
    border: 1px solid ${({ buttonBackground }) => buttonBackground === styles.colors.background ? styles.colors.lightGray : styles.colors.blue};
    border-radius: 1px;
    padding: 12px 24px;
    align-self: center;
    margin-bottom: 32px;
`

const StyledSpan = styled.span`
    font-family: ${styles.fonts.nunito};
    font-weight: 800;
`

export default function ManageEmailSubscriptions({ data }) {

    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState(data?.subscriptions);

    const toggleGroupSubscription = async group => {
        try {
            const { data: postData } = await post('/manage-subscriptions/toggle', { email: data?.email, group });
            if (!postData?.success) throw new Error(postData?.message ?? 'An error occurred');
            setSubscriptions(postData.subscriptions)
            return true
        } catch (error) {
            console.error('toggleGroupSubscription error', error);
            return false
        }
    }

    const onButtonClick = async () => {
        try {
            setLoading(true);
            const { data: postData } = await post('/manage-subscriptions/unsubscribe_all', { email: data?.email, unsubscribe: !!subscriptions.find(sub => sub.is_unsubscribed === false) });
            if (!postData?.success) throw new Error(postData?.message ?? 'An error occurred');
            setSubscriptions(postData.subscriptions)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const allUnSubscribed = useMemo(() => {
        return subscriptions.every(sub => sub.is_unsubscribed);
    }, [subscriptions])

    const buttonText = useMemo(() => {
        return allUnSubscribed ? 'Subscribe to All' : 'Unsubscribe from All';
    }, [allUnSubscribed])

    const buttonBackground = useMemo(() => {
        return allUnSubscribed ? styles.colors.blue : styles.colors.background;
    }, [allUnSubscribed])

    const buttonColor = useMemo(() => {
        return allUnSubscribed ? styles.colors.white : styles.colors.lightGray;
    }, [allUnSubscribed])

    return (
        <ScreenWrapper>
            <StyledContainer>
                <Heading variant='bold' color={styles.colors.whiteGray}>Email Preferences</Heading>
                <Heading color={styles.colors.whiteGray} type='h5'>{data.message === 'success' ? `Update email subscription settings for: ${data?.email}` : data.message}</Heading>
                {data.message === 'success' && (
                    <Row>
                        <Col>
                            <ItemsWrapper>
                                {subscriptions.map(({ type, is_unsubscribed, subtitle, group }) => (
                                    <SubscriptionItem {...{ type, is_unsubscribed, subtitle, group, toggleGroupSubscription }} />
                                ))}
                            </ItemsWrapper>
                            <CenteredDiv>
                                <StyledButton disabled={loading} spinnerVariant={allUnSubscribed ? 'dark' : 'light'} onClick={onButtonClick} {...{ loading, buttonBackground }}>
                                    <Text.Body color={buttonColor} variant='bold'>{buttonText}</Text.Body>
                                </StyledButton>
                                <Text.Body style={{ textAlign: 'center' }} color={styles.colors.lightGray}>
                                    <StyledSpan>It may take up to 10 business days to complete your email unsubscribe request. </StyledSpan>
                                    You will continue to receive transactional or critical service emails associated with your account are not from a marketing group and will still be sent.
                                </Text.Body>
                            </CenteredDiv>
                        </Col>
                    </Row>
                )}
            </StyledContainer>
        </ScreenWrapper>
    );
}