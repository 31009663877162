import React from 'react'
import styled from 'styled-components';
import Button from '../../shared/Button';
import Text from '../../shared/Text';
import { Phone, Pro, Clock, Dismiss } from '../../../utility/icons';
import { useSession } from '../../../utility/hooks';
import styles from '../../../utility/styles';
import { post, useStandardState } from '../../../utility/api';
import { asyncDelay, returnTimeString } from '../../../utility/helpers';
import Spinner from '../../shared/Spinner';
import DropUp from './DropUp';

const StyledRow = styled.div`
    display: flex;
    align-items: center;
`;

const StyledHr = styled.hr`
    flex: 4;
`;

const UpgradeContainer = styled.div`
    padding: 0 16px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${styles.colors.darkGray};
    margin: auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin: auto;
    align-items: flex-end;
`;

const RecentRow = styled.div`
    display: flex;
`;

const RecentCol = styled.div`
    display: flex;
    flex-direction: column;
`;

const Divider = styled.div`
    height: 40px;
    width: 1px;
    background-color: ${styles.colors.lightGray};
    margin: 0 24px;
`;

const VerticalDivider = styled.div`
    height: 70px;
    width: 1px;
    background-color: ${styles.colors.mediumGray};
    margin: 0 24px;
`;

/**
 * 
 * @param {{pro:boolean, recent:array, options:array, expanded:array, cancelAction:string, climateScheduledTs?:number, vehicleName:string, timezone:string}} props
 * @returns 
 */
export default function ClimateFooter({ pro, recent, options, expanded, cancelAction, climateScheduledTs, vehicleName, timezone }) {

    const session_id = useSession();
    const [{ error, isFetching, data }, { startFetching, recieveData, recieveError }] = useStandardState();
    const [activeOption, setActiveOption] = React.useState('');
    const [upgradeSent, setUpgradeSent] = React.useState('');

    const buttonList = React.useMemo(() => expanded.map(({ option, action }) => ({ text: option, onClick: () => setClimate(option, action) })))

    const setClimate = React.useCallback(async (option, action) => {
        if (!action) return;
        setActiveOption(option)
        startFetching();
        const { data: climateData, success, error } = await post(action);
        if (error) recieveError(error)
        if (climateData) recieveData({ climateScheduledTs: climateData.climate_scheduled_ts })
        setActiveOption('')
    }, [])

    React.useEffect(() => {
        recieveData({ climateScheduledTs })
    }, [climateScheduledTs])

    const pushUpgradeNotification = React.useCallback(async () => {
        try {
            setUpgradeSent('loading');
            await post(`/dash/${session_id}/action/pro_upgrade_push_climate`);
            setUpgradeSent('sent')
            await asyncDelay(5000);
            setUpgradeSent('')
        } catch (error) {
            setUpgradeSent('error')
            await asyncDelay(5000);
            setUpgradeSent('')
        }
    }, [setUpgradeSent])

    return (
        <div>
            {Boolean(data?.climateScheduledTs) ? (
                <div>
                    <StyledRow>
                        <StyledHr />
                    </StyledRow>
                    <StyledRow style={{ justifyContent: 'space-between' }}>
                        <StyledRow>
                            <Clock style={{ marginRight: '8px' }} />
                            <Text.Heading type='h4' variant='bold'>{vehicleName} is scheduled to start conditioning {returnTimeString(data?.climateScheduledTs, { timeDiff: 90000, replacementText: 'momentarily', prefix: 'at ', timezone })}</Text.Heading>
                        </StyledRow>
                        <Button
                            loading={activeOption === 'Cancel'}
                            onClick={() => setClimate('Cancel', cancelAction)}
                            style={{ padding: '0', backgroundColor: 'transparent' }}
                        >
                            <Dismiss style={{ marginRight: '8px' }} />
                        </Button>
                    </StyledRow>
                </div>
            ) : (
                <React.Fragment>
                    <StyledRow style={{ marginBottom: '32px' }}>
                        <StyledHr />
                        <Text.Heading variant='bold' type='h4' style={{ flex: 2, margin: '0 32px', textAlign: 'center' }}>Schedule Climate</Text.Heading>
                        <StyledHr />
                    </StyledRow>
                    <StyledRow>
                        {pro ? (
                            <ButtonContainer>
                                {Boolean(recent.length) && (
                                    <React.Fragment>
                                        <RecentCol>
                                            <Text.Tiny variant='bold' style={{ color: styles.colors.lightGray, margin: '0 0 8px 4px' }}>Recently Used</Text.Tiny>
                                            <RecentRow>
                                                {recent.map(({ option, action }) => (
                                                    <Button
                                                        loading={isFetching && activeOption === option}
                                                        onClick={() => setClimate(option, action)}
                                                        key={option}
                                                        variant='secondary'
                                                        style={{ margin: '0 4px', paddingLeft: '24px', paddingRight: '24px' }}
                                                    >
                                                        <Text.Heading variant='bold' type='h4'>{option}</Text.Heading>
                                                    </Button>
                                                ))}
                                            </RecentRow>
                                        </RecentCol>
                                        <VerticalDivider />
                                    </React.Fragment>
                                )}
                                {options.map(({ option, action }) => (
                                    <Button
                                        loading={isFetching && activeOption === option}
                                        onClick={() => setClimate(option, action)}
                                        key={option}
                                        variant='secondary'
                                        style={{ margin: '0 4px', paddingLeft: '24px', paddingRight: '24px' }}
                                    >
                                        <Text.Heading variant='bold' type='h4'>{option}</Text.Heading>
                                    </Button>
                                ))}
                                <DropUp {...{ buttonList }} />
                            </ButtonContainer>
                        ) : (
                            <UpgradeContainer>
                                <Pro style={{ marginRight: '8px' }} />
                                <Text.Heading type='h6'>Upgrade to TezLab Pro to access automated climate features.</Text.Heading>
                                <Divider />
                                <Button onClick={pushUpgradeNotification} style={{ backgroundColor: 'transparent' }}>
                                    {upgradeSent === 'loading' ? (
                                        <Spinner
                                            color={styles.colors.blue}
                                            size={32}
                                            style={{ marginRight: '8px' }}
                                        />
                                    ) : (
                                        <Phone fill={styles.colors.blue} style={{ marginRight: '8px' }} />
                                    )}
                                    <Text.Heading type='h6' variant='bold' style={{ color: styles.colors.blue }}>{upgradeSent === 'sent' ? 'Notification Sent' : 'Upgrade using app'}</Text.Heading>
                                </Button>
                            </UpgradeContainer>
                        )}
                    </StyledRow>
                </React.Fragment>
            )}
        </div>
    )
}