import React, { useContext, createContext } from 'react'

// session related hooks

const SessionContext = createContext()

export const SessionProvider = ({ session_id, ...props }) => {
	return <SessionContext.Provider {...props} value={session_id} />
}

export const useSession = () => useContext(SessionContext)

/**
 * 
 * @param {string[]} activePaths 
 * @returns 
 */
export const useIsActive = (activePaths = []) => {
	const { pathname } = window.location
    const isActive = React.useMemo(() => activePaths.map(path => new RegExp(path).test(pathname)).includes(true), [activePaths, pathname])
	return isActive
}
