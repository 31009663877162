import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from 'react-bootstrap/Switch';
import Spinner from 'react-bootstrap/Spinner';
import Text from '../../shared/Text';
import styles from '../../../utility/styles';


const ItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid ${styles.colors.darkGray};
    border-radius: 4px;
    background-color: ${({ subscribed }) => subscribed ? styles.colors.darkGray : styles.colors.background};
    margin-bottom: 12px;
    padding: 16px 48px;
    transition: all 0.3s ease;
    @media screen and (max-width: 768px) {
        padding: 16px;
    }
`

const StyledSwitch = styled(Switch)`
    display: flex;
    & .form-check-input {
        cursor: pointer;
        height: 32px;
        width: 48px;
        background-color: ${styles.colors.lightGray};
        border-color: ${styles.colors.lightGray};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e");
        &:checked {
            background-color: ${styles.colors.success};
            border-color: ${styles.colors.success};
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e");
        }
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
`

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 32px;
`

/**
 * 
 * @param {{
 *   type: string,
 *   group: string,
 *   subtitle: string,
 *   is_unsubscribed: boolean,
 *   toggleGroupSubscription: (group: string) => Promise<boolean>
 * }} props
 * @returns 
 */
export default function SubscriptionItem({ type, subtitle, group, is_unsubscribed, toggleGroupSubscription }) {
    const [isLoading, setIslLoading] = useState(false);
    const toggleGroupSubscriptionInternal = async () => {
        try {
            setIslLoading(true);
            const success = await toggleGroupSubscription(group);
            if (!success) throw new Error('An error occurred');
            setIslLoading(false);
        } catch (error) {
            setIslLoading(false);
        }
    }

    return (
        <ItemWrapper subscribed={!is_unsubscribed}>
            <TextWrapper>
                <Text.Heading variant='bold' type='h3' color={styles.colors.whiteGray}>{type}</Text.Heading>
                <Text.Body color={styles.colors.whiteGray}>{subtitle}</Text.Body>
            </TextWrapper>
            {isLoading ? (
                <SpinnerWrapper>
                    <Spinner animation='border' variant='light' />
                </SpinnerWrapper>
            ) : (
                <StyledSwitch
                    disabled={isLoading}
                    checked={!is_unsubscribed}
                    onChange={toggleGroupSubscriptionInternal}
                />
            )}
        </ItemWrapper>
    )
}