import React from 'react';
import Text from '../shared/Text';
import ScreenWrapper from '../shared/ScreenWrapper';

export default function Stats({ }) {
    return (
        <ScreenWrapper>
            <Text.Huge style={{ textAlign: 'center' }}>Coming Soon!</Text.Huge>
        </ScreenWrapper>
    )
}