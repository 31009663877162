import styled, { css } from 'styled-components'
import React, { DOMAttributes, useMemo } from 'react'
import { Nav, NavLinkProps } from 'react-bootstrap'
import styles from '../../utility/styles'
import { useIsActive } from '../../utility/hooks' // this is a server rendered specific thing...

const smashWidows = (children, allowWidows = false) => {
	if (typeof children !== 'string' || allowWidows) return children
	const space = children.lastIndexOf(' ')
	if (space < 0) return children
	return `${children.substring(0, space)}\u00A0${children.substring(
		space + 1
	)}`
}

export const withSmashWidows = Component => {
	return ({ children: kiddos, allowWidows, ...props }) => {
		const children = smashWidows(kiddos, allowWidows)
		return <Component {...{ children }} {...props} />
	}
}

const H1Font = css`
	font-size: 40px;
	line-height: 55px;
`

const H2Font = css`
	font-size: 32px;
	line-height: 44px;
`

const H3Font = css`
	font-size: 28px;
	line-height: 38px;
`

const H4Font = css`
	font-size: 24px;
	line-height: 32px;
`

const H5Font = css`
	font-size: 20px;
	line-height: 28px;
`

const H6Font = css`
	font-size: 18px;
	line-height: 25px;
`

const BodyFont = css`
	font-size: 16px;
	line-height: 26px;
`

const ParaFont = css`
	font-size: 16px;
	line-height: 28px;
`

const CaptionFont = css`
	font-size: 14px;
	line-height: 20px;
`

const MegaFont = css`
	font-size: 56px;
	line-height: 76px;
`

const UltraFont = css`
	font-size: 80px;
	line-height: 109px;
`

const fonts = {
	h1: H1Font,
	h2: H2Font,
	h3: H3Font,
	h4: H4Font,
	h5: H5Font,
	h6: H6Font,
	body: BodyFont,
	para: ParaFont,
	caption: CaptionFont,
	mega: MegaFont,
	ultra: UltraFont,
}

const bodyMassive = css`
	font-size: 24px;
	line-height: 32px;
`

const h1Massive = css`
	font-size: 56px;
	line-height: 76px;
`

const H2Massive = css`
	font-size: 56px;
	line-height: 76px;
`

const MegaMassive = css`
	font-size: 80px;
	line-height: 109px;
`

const Tiny = styled.p`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	font-size: 11px;
	line-height: 14px;
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
`

const Caption = styled.p`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${CaptionFont};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
`

const Para = styled.p`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${ParaFont};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
`

const Body = styled.p`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${BodyFont};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.massive} {
		${({ goBig }) => (goBig ? bodyMassive : undefined)}
	}
`

const H1 = styled.h1`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${H1Font};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.massive} {
		${({ goBig }) => (goBig ? h1Massive : undefined)}
	}
	@media only screen and ${styles.breakpoints.tabletP} {
		${({ tabletP }) =>
		typeof tabletP === 'string' ? fonts[tabletP] : undefined}
	}
	@media only screen and ${styles.breakpoints.tablet} {
		${({ tablet }) =>
		typeof tablet === 'string' ? fonts[tablet] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileL} {
		${({ mobileL }) =>
		typeof mobileL === 'string' ? fonts[mobileL] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileS} {
		${({ mobileS }) =>
		typeof mobileS === 'string' ? fonts[mobileS] : undefined}
	}
`

const H2 = styled.h2`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${H2Font};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.massive} {
		${({ goBig }) => (goBig ? H2Massive : undefined)}
	}
	@media only screen and ${styles.breakpoints.tabletP} {
		${({ tabletP }) =>
		typeof tabletP === 'string' ? fonts[tabletP] : undefined}
	}
	@media only screen and ${styles.breakpoints.tablet} {
		${({ tablet }) =>
		typeof tablet === 'string' ? fonts[tablet] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileL} {
		${({ mobileL }) =>
		typeof mobileL === 'string' ? fonts[mobileL] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileS} {
		${({ mobileS }) =>
		typeof mobileS === 'string' ? fonts[mobileS] : undefined}
	}
`

const H3 = styled.h3`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${H3Font};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.tabletP} {
		${({ tabletP }) =>
		typeof tabletP === 'string' ? fonts[tabletP] : undefined}
	}
	@media only screen and ${styles.breakpoints.tablet} {
		${({ tablet }) =>
		typeof tablet === 'string' ? fonts[tablet] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileL} {
		${({ mobileL }) =>
		typeof mobileL === 'string' ? fonts[mobileL] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileS} {
		${({ mobileS }) =>
		typeof mobileS === 'string' ? fonts[mobileS] : undefined}
	}
`

const H4 = styled.h4`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${H4Font};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.tabletP} {
		${({ tabletP }) =>
		typeof tabletP === 'string' ? fonts[tabletP] : undefined}
	}
	@media only screen and ${styles.breakpoints.tablet} {
		${({ tablet }) =>
		typeof tablet === 'string' ? fonts[tablet] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileL} {
		${({ mobileL }) =>
		typeof mobileL === 'string' ? fonts[mobileL] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileS} {
		${({ mobileS }) =>
		typeof mobileS === 'string' ? fonts[mobileS] : undefined}
	}
`

const H5 = styled.h5`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${H5Font};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.tabletP} {
		${({ tabletP }) =>
		typeof tabletP === 'string' ? fonts[tabletP] : undefined}
	}
	@media only screen and ${styles.breakpoints.tablet} {
		${({ tablet }) =>
		typeof tablet === 'string' ? fonts[tablet] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileL} {
		${({ mobileL }) =>
		typeof mobileL === 'string' ? fonts[mobileL] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileS} {
		${({ mobileS }) =>
		typeof mobileS === 'string' ? fonts[mobileS] : undefined}
	}
`

const H6 = styled.h6`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${H6Font};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.tabletP} {
		${({ tabletP }) =>
		typeof tabletP === 'string' ? fonts[tabletP] : undefined}
	}
	@media only screen and ${styles.breakpoints.tablet} {
		${({ tablet }) =>
		typeof tablet === 'string' ? fonts[tablet] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileL} {
		${({ mobileL }) =>
		typeof mobileL === 'string' ? fonts[mobileL] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileS} {
		${({ mobileS }) =>
		typeof mobileS === 'string' ? fonts[mobileS] : undefined}
	}
`

const Ultra = styled.h1`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${UltraFont};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.tabletP} {
		${({ tabletP }) =>
		typeof tabletP === 'string' ? fonts[tabletP] : undefined}
	}
	@media only screen and ${styles.breakpoints.tablet} {
		${({ tablet }) =>
		typeof tablet === 'string' ? fonts[tablet] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileL} {
		${({ mobileL }) =>
		typeof mobileL === 'string' ? fonts[mobileL] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileS} {
		${({ mobileS }) =>
		typeof mobileS === 'string' ? fonts[mobileS] : undefined}
	}
`

const Mega = styled.h1`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${MegaFont};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	@media only screen and ${styles.breakpoints.massive} {
		${({ goBig }) => (goBig ? MegaMassive : undefined)}
	}
	@media only screen and ${styles.breakpoints.tabletP} {
		${({ tabletP }) =>
		typeof tabletP === 'string' ? fonts[tabletP] : undefined}
	}
	@media only screen and ${styles.breakpoints.tablet} {
		${({ tablet }) =>
		typeof tablet === 'string' ? fonts[tablet] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileL} {
		${({ mobileL }) =>
		typeof mobileL === 'string' ? fonts[mobileL] : undefined}
	}
	@media only screen and ${styles.breakpoints.mobileS} {
		${({ mobileS }) =>
		typeof mobileS === 'string' ? fonts[mobileS] : undefined}
	}
`

const headings = {
	h1: H1,
	h2: H2,
	h3: H3,
	h4: H4,
	h5: H5,
	h6: H6,
}

const huges = {
	ultra: Ultra,
	mega: Mega,
}

/**
 *
 * @typedef {('h2'|'h3'|'h4'|'h5'|'h6'|'body'|'caption'|'para'|'mega'|'ultra')} size
 */

/**
 *
 * @param {{variant?:'bold'|'regular', type?:'h1'|'h2'|'h3'|'h4'|'h5'|'h6', goBig?:boolean, tabletP?:size, tablet?:size, mobileL?:size, mobileS?:size}&DOMAttributes} props
 * @returns
 */
const Heading = ({
	variant = 'regular',
	type = 'h1',
	goBig = false,
	...props
}) => {
	const Comp = headings[type] || H1
	return <Comp {...{ variant, goBig }} {...props} />
}

/**
 *
 * @param {{variant?:'bold'|'regular', type?:'ultra'|'mega', goBig?:boolean, tabletP?:size, tablet?:size, mobileL?:size, mobileS?:size}&DOMAttributes} props
 * @returns
 */
const Huge = ({
	variant = 'regular',
	type = 'mega',
	goBig = false,
	...props
}) => {
	const Comp = huges[type] || Mega
	return <Comp {...{ variant, goBig }} {...props} />
}

const NavLinkText = styled.p`
	white-space: nowrap;
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	font-size: 12px;
	line-height: 14px;
	color: ${({ isActive, color }) =>
		color
			? color
			: isActive
				? styles.colors.blue
				: styles.colors.white};
	text-transform: none;
	margin: ${({ horizontal }) => (horizontal ? '0 0 0 8px' : '0')};
`

/**
 *
 * @param {{iconComponent:JSX.Element, activePaths:string[], children:string, horizontal?:boolean, color:string}&NavLinkProps} props
 * @returns
 */
const NavLink = ({ iconComponent, children, activePaths = [], horizontal = false, color, ...props }) => {
	const isActive = useIsActive(activePaths)
	const style = useMemo(() => ({ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: horizontal ? 'row' : 'column' }), [horizontal])
	return (
		<Nav.Link {...props}>
			<div
				{...{ style }}
			>
				{iconComponent &&
					React.cloneElement(iconComponent, { isActive })}
				<NavLinkText {...{ isActive, horizontal, color }}>{children}</NavLinkText>
			</div>
		</Nav.Link>
	)
}

const StyledLink = styled.a`
	font-family: ${styles.fonts.nunito};
	font-weight: ${({ variant }) => (variant === 'bold' ? 800 : 600)};
	${({ baseFont }) => baseFont};
	color: ${({ color }) => (color ? color : `${styles.colors.white}`)};
	margin: 0;
	text-decoration: underline;
`

/**
 *
 * @param {{variant:'regular'|'bold', font:'h1'|'h2'|'h3'|'h4'|'h5'|'body'|'para'|'tiny'|'caption'|'mega'|'ultra', color:string}&DOMAttributes} param0
 * @returns
 */
const Link = ({ variant = 'regular', font, color, ...props }) => {
	const baseFont = fonts[font] ?? fonts.caption

	return <StyledLink {...{ variant, color, baseFont }} {...props} />
}

export default {
	Tiny,
	Caption,
	Para,
	Body,
	Heading: withSmashWidows(Heading),
	Huge: withSmashWidows(Huge),
	NavLink,
	Link,
}
