import React from 'react';
import { Container } from 'react-bootstrap';
import Text from '../shared/Text';
import Accordion from '../shared/Accordion';
import ScreenWrapper from '../shared/ScreenWrapper';

export default function Faq({ faq, openedSlug, isWebView }) {
    
    return (
        <ScreenWrapper {...{ isWebView }}>
            <Text.Heading type='h2' variant='bold' style={{ textAlign: 'center', marginBottom: '48px', marginTop: '64px' }}>FAQ</Text.Heading>
            <Container>
                {Array.isArray(faq.faq_items) && faq.faq_items.map(({ category_name, slug, items }) => (
                    <Accordion style={{ marginBottom: '48px' }} useParser key={slug} title={category_name} rows={items} {...{ openedSlug }} />
                ))}
            </Container>
        </ScreenWrapper>
    )
}