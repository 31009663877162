import styles from '../../utility/styles'
import { sliceIt } from '../../utility/helpers'
import { useMemo } from 'react'

/**
 *
 * @param {{
 *   fraction:number,
 *   id:string,
 *   scale?:number,
 *   colors?:{background:string,foreground:string}
 * }} props
 * @returns
 */
export const Star = ({ fraction, id, scale = 1, colors = {} }) => {
	const {
		background = styles.colors.darkGray,
		foreground = styles.colors.blue,
	} = colors
	const marginRight = useMemo(() => `-${24 - 24 * scale}px`, [scale])
	return (
		<svg
			style={{ scale: scale.toFixed(1), marginRight }}
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<defs>
				<clipPath {...{ id }}>
					<rect x='0' y='0' height='24' width={`${24 * fraction}`} />
				</clipPath>
			</defs>
			<path
				d='M6.59427 21.143C6.33832 21.143 6.08238 21.0619 5.85897 20.8997C5.41239 20.5752 5.2339 20.0262 5.40483 19.501L7.16527 14.0834C7.17947 14.0401 7.16389 13.9927 7.127 13.9658L2.5182 10.6173C2.07161 10.2928 1.89312 9.74382 2.06382 9.21887C2.23453 8.6937 2.7015 8.35458 3.25349 8.35458H8.94999C8.99582 8.35458 9.03592 8.32548 9.05013 8.28194L10.8106 2.86429C10.981 2.33912 11.448 2 12 2C12.552 2 13.019 2.33912 13.1894 2.86429L14.9499 8.28217C14.9641 8.32548 15.0042 8.35481 15.05 8.35481H20.7465C21.2985 8.35481 21.7655 8.69393 21.9362 9.2191C22.1069 9.74428 21.9284 10.2931 21.4818 10.6175L16.8732 13.9658C16.8363 13.9927 16.821 14.0399 16.835 14.0834L18.5954 19.501C18.7661 20.0262 18.5876 20.575 18.141 20.8997C17.6944 21.2241 17.1173 21.2241 16.6707 20.8997L12.0619 17.5513C12.0247 17.5245 11.9753 17.5245 11.9381 17.5513L7.32956 20.8997C7.10615 21.0619 6.85044 21.143 6.59427 21.143Z'
				fill={background}
			/>
			<path
				d='M6.59427 21.143C6.33832 21.143 6.08238 21.0619 5.85897 20.8997C5.41239 20.5752 5.2339 20.0262 5.40483 19.501L7.16527 14.0834C7.17947 14.0401 7.16389 13.9927 7.127 13.9658L2.5182 10.6173C2.07161 10.2928 1.89312 9.74382 2.06382 9.21887C2.23453 8.6937 2.7015 8.35458 3.25349 8.35458H8.94999C8.99582 8.35458 9.03592 8.32548 9.05013 8.28194L10.8106 2.86429C10.981 2.33912 11.448 2 12 2C12.552 2 13.019 2.33912 13.1894 2.86429L14.9499 8.28217C14.9641 8.32548 15.0042 8.35481 15.05 8.35481H20.7465C21.2985 8.35481 21.7655 8.69393 21.9362 9.2191C22.1069 9.74428 21.9284 10.2931 21.4818 10.6175L16.8732 13.9658C16.8363 13.9927 16.821 14.0399 16.835 14.0834L18.5954 19.501C18.7661 20.0262 18.5876 20.575 18.141 20.8997C17.6944 21.2241 17.1173 21.2241 16.6707 20.8997L12.0619 17.5513C12.0247 17.5245 11.9753 17.5245 11.9381 17.5513L7.32956 20.8997C7.10615 21.0619 6.85044 21.143 6.59427 21.143Z'
				clipPath={`url(#${id})`}
				fill={foreground}
			/>
		</svg>
	)
}

/**
 *
 * @param {{
 *   ratings:number,
 *   outOf?:number,
 *   scale?:number,
 *   uuid:string|number,
 *   colors?:{
 *     background:string, foreground:string
 *   }
 * }} props
 * @returns
 */

export const Stars = ({ ratings, outOf, scale, uuid = '', colors }) => {
	const stars = sliceIt(ratings, outOf)
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{stars.map((star, i) => (
				<Star
					key={`star=${i + 1}${uuid}`}
					id={`star=${i + 1}${uuid}`}
					fraction={star}
					{...{ scale, colors }}
				/>
			))}
		</div>
	)
}
