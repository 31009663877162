import React from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import Text from '../../shared/Text';
import { isTesla } from '../../../utility/helpers';
import styles from '../../../utility/styles';
import Spinner from '../../shared/Spinner';
import { useSession } from '../../../utility/hooks';

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const QRImage = styled(Image)`
    width: 30vw;
    border: 8px solid #EEEEEE;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.48));
    border-radius: 8px;
    margin: 48px 0;
`;

/**
 * 
 * @param {{session_id:string}} props
 */
export default function CodeSection({ error, isFetching }) {

    const session_id = useSession();

    if (error) {
        return (
            <Wrapper>
                <Text.Heading type='h5' style={{ textAlign: 'center', color: styles.colors.lightGray }}>{error}</Text.Heading>
                <Spinner size={40} style={{ marginTop: '24px', opacity: isFetching ? 1 : 0, transition: 'opacity 0.3s ease-in-out' }} />
            </Wrapper>
        )
    }
    
    return (
        <Wrapper>
            <TextWrapper>
                <Text.Heading variant='bold' type='h2' style={{ textAlign: 'center' }}>Drive informed with the In-Car Dashboard</Text.Heading>
                <Text.Heading type='h5' style={{ textAlign: 'center' }}>Scan the QR code below with your phone that has TezLab installed to authenticate.</Text.Heading>
            </TextWrapper>
            <QRImage src={`${session_id}/qrcode.png`} />
            {!isTesla() && <Text.Heading type='h6' variant='bold' style={{ textAlignt: 'center' }}>Open this page in your car's browser for the absolute best experience.</Text.Heading>}
        </Wrapper>
    )
}