import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import ScreenWrapper from '../../shared/ScreenWrapper'
import Text from '../../shared/Text'
import Spinner from '../../shared/Spinner'
import { dissectName } from '../../../utility/helpers'
import styles from '../../../utility/styles'
import {
	ArrowRight,
	FoundersIcon,
	ProIconGold,
	ProIconBlue,
	FreeIcon,
} from '../../../utility/icons'
import GiftRedemptionPanel from './GiftRedemptionPanel'

const StyeldContainer = styled(Container)`
	margin-top: 72px;
	margin-bottom: 72px;
`

const NoUserWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 4px;
	border: 1px solid ${styles.colors.darkGray};
	padding: 24px;
	margin: 48px 0;
`

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 48px 0;
`

const SubscriptionsWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const PanelWrapper = styled.button`
	display: flex;
	position: relative;
	align-items: center;
	border: 2px solid ${({ offer }) => offer ? styles.colors.founders : styles.colors.darkGray};
	margin-bottom: 8px;
	border-radius: 4px;
	background-color: ${({ active }) =>
		active ? styles.colors.background : styles.colors.darkGray};
	padding: 0;
`

const SubscriptionPanel = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	padding: 12px 38px;
`

const MyCol = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 12px;
	flex: 1;
`

const TitleRow = styled.div`
	display: flex;
	align-items: center;
`

const OfferBadgeWrapper = styled.div`
	padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid ${styles.colors.founders};
    margin-left: 16px;
    align-self: middle;
`

const OfferNoticeWrapper = styled.div`
	display: flex;
	height: 100%;
	width: 24px;
	position: absolute;
	justify-content: center;
	align-items: center;
	background-color: ${styles.colors.founders};
`

const OfferText = styled(Text.Caption)`
	transform: rotate(90deg);
	min-width: 90px;
`

const FakeButton = styled.div`
	display: flex;
	align-items: center;
	border-radius: 1px;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: ${({ loading }) => (loading ? '0' : '12px')};
	color: ${({ active }) =>
		active ? styles.colors.background : styles.colors.whiteGray};
	background-color: ${({ active, loading }) =>
		loading
			? 'transparent'
			: active
				? styles.colors.success
				: 'transparent'};
`

const PanelColLeft = styled(Col)`
	display: flex;
	align-items: center;
	padding: 0;
`

const PanelColRight = styled(Col)`
	display: flex;
	align-items: center;
	padding: 0;
	justify-content: flex-end;
	@media only screen and ${styles.breakpoints.tablet} {
		justify-content: flex-start;
		margin-left: ${({ isFree }) => isFree ? '44px' : '32px'};
	}
`

const PanelRow = styled(Row)`
	display: flex;
	width: 100%;
	margin: 0;
`

const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 4px 16px 4px 4px;
	border-radius: 20px;
	background-color: ${styles.colors.darkGray};
	margin-bottom: 8px;
`

const AvatarBacker = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: content-box;
	border: 2px solid
		${({ show }) => (show ? 'transparent' : styles.colors.blue)};
	height: 28px;
	width: 28px;
	border-radius: 16px;
	margin-right: 8px;
	background-color: ${styles.colors.darkGray};
`

const Avatar = styled.img`
	position: absolute;
	width: 32px;
	height: 32px;
	background-color: ${styles.colors.darkGray};
`

const AvatarIcon = ({ subscription }) => {
	if (
		!subscription?.subscription_type ||
		subscription?.subscription_type === 'standard'
	)
		return false
	if (subscription.subscription_type === 'founders_series')
		return (
			<FoundersIcon
				width='20'
				height='20'
				style={{ marginLeft: '8px' }}
			/>
		)

	return <ProIconBlue width='20' height='20' style={{ marginLeft: '8px' }} />
}

const IconForType = ({ type }) => {
	switch (type) {
		case 'founders_series':
			return <FoundersIcon style={{ overflow: 'visible' }} />
		case 'free':
			return <FreeIcon style={{ overflow: 'visible' }} />
		default:
			return <ProIconGold style={{ overflow: 'visible' }} />
	}
}

/**
 *
 * @param {{
 *   user:{
 *     avatar:string,
 *     email:string,
 *     id:number,
 *     is_free_user:boolean,
 *     name:string
 *   },
 *   subscription_options:[{
 *     action:string,
 *     eligible_for_trial:boolean,
 *     is_current:boolean,
 *     link_target?:string,
 *     link_text?:string,
 *     status_text:string,status_type:string,
 *     subtitle?:string,
 *     title:string,type:string,
 *     offer:boolean,
 *     offer_badge_text?:string
 *   }],
 * 	gift_redemption_lookup:string,
 *  redemption_code_prefill:string
 * }} props
 * @returns
 */
export default function ManageSubscription({ user, subscription_options, gift_redemption_lookup, redemption_code_prefill }) {

	const [loading, setLoading] = useState('')

	const navigateTo = (action, type) => {
		if (action) {
			setLoading(type)
			window.location.href = action
		}
	}

	return (
		<ScreenWrapper>
			<StyeldContainer>
				<Text.Heading
					color={styles.colors.whiteGray}
					variant='bold'
					style={{ textAlign: 'center' }}
				>
					My Subscription
				</Text.Heading>
				<Row>
					<Col>
						{Boolean(user) ? (
							<div>
								<TextWrapper>
									<AvatarWrapper>
										<AvatarBacker
											show={Boolean(user.avatar)}
										>
											<Text.Caption
												color={styles.colors.blue}
												variant='bold'
											>
												{dissectName(user.name)}
											</Text.Caption>
											{Boolean(user.avatar) && (
												<Avatar src={user.avatar} />
											)}
										</AvatarBacker>
										<Text.Body
											color={styles.colors.whiteGray}
											variant='bold'
										>
											{user.name}
										</Text.Body>
										<AvatarIcon
											subscription={user.subscription}
										/>
									</AvatarWrapper>
									<Text.Heading
										color={styles.colors.whiteGray}
										type='h5'
									>
										{user.email}
									</Text.Heading>
								</TextWrapper>
								<SubscriptionsWrapper>
									{user.is_free_user && (
										<PanelWrapper
											active={user.is_free_user}
											disabled={true}
										>
											<SubscriptionPanel>
												<PanelRow>
													<PanelColLeft md='8' xs='12'>
														<IconForType type='free' />
														<MyCol>
															<Text.Heading
																type='h2'
																mobileL='h3'
																mobileS='h5'
																allowWidows
																variant='bold'
																color={
																	styles.colors
																		.whiteGray
																}
																style={{
																	textAlign:
																		'left',
																}}
															>
																TezLab Basic
															</Text.Heading>
															<Text.Body
																color={
																	styles.colors
																		.whiteGray
																}
															>
																Free
															</Text.Body>
														</MyCol>
													</PanelColLeft>
													<PanelColRight md='4' xs='12' isFree>
														<FakeButton
															active={
																user.is_free_user
															}
															style={{
																paddingRight:
																	'12px',
															}}
														>
															<Text.Body
																variant='bold'
																style={{
																	color: 'inherit',
																}}
															>
																Active
															</Text.Body>
														</FakeButton>
													</PanelColRight>
												</PanelRow>
											</SubscriptionPanel>
										</PanelWrapper>
									)}
									{subscription_options.map(option => (
										<PanelWrapper
											offer={option.offer}
											onClick={
												option.action
													? () =>
														navigateTo(
															option.action,
															option.type
														)
													: null
											}
											key={option.type}
											disabled={Boolean(loading)}
											active={option.is_current}
										>
											{option.offer && (
												<OfferNoticeWrapper>
													<OfferText variant='bold' color={styles.colors.black}>Special Offer</OfferText>
												</OfferNoticeWrapper>
											)}
											<SubscriptionPanel>
												<PanelRow>
													<PanelColLeft md='8' xs='12'>
														<IconForType
															type={option.type}
														/>
														<MyCol>
															<TitleRow>
																<Text.Heading
																	type='h2'
																	mobileL='h3'
																	mobileS='h5'
																	allowWidows
																	variant='bold'
																	color={
																		styles.colors
																			.whiteGray
																	}
																	style={{
																		textAlign:
																			'left',
																	}}
																>
																	{option.title}
																</Text.Heading>
																{Boolean(option.offer_badge_text) && (
																	<OfferBadgeWrapper>
																		<Text.Caption variant='bold' color={styles.colors.founders}>{option.offer_badge_text}</Text.Caption>
																	</OfferBadgeWrapper>
																)}
															</TitleRow>
															<Text.Body
																color={
																	styles.colors
																		.whiteGray
																}
																style={{
																	textAlign:
																		'start',
																}}
															>
																{option.subtitle}
															</Text.Body>
														</MyCol>
													</PanelColLeft>
													<PanelColRight md='4' xs='12' isFree={option.is_current}>
														<FakeButton
															active={
																option.is_current
															}
															loading={loading === option.type}
														>
															{loading !==
																option.type && (
																	<Text.Body
																		variant='bold'
																		style={{
																			color: 'inherit',
																		}}
																	>
																		{
																			option.status_text
																		}
																	</Text.Body>
																)}
															{option.action ?
																loading !==
																option.type && (
																	<ArrowRight
																		fill={
																			option.is_current
																				? styles
																					.colors
																					.background
																				: styles
																					.colors
																					.whiteGray
																		}
																		style={{
																			marginLeft:
																				'8px',
																		}}
																	/>
																) : (
																	<div style={{ paddingRight: '12px' }} />
																)}
															{loading ===
																option.type && (
																	<Spinner
																		color={
																			styles
																				.colors
																				.green
																		}
																		style={{
																			marginLeft:
																				'8px',
																		}}
																	/>
																)}
														</FakeButton>
													</PanelColRight>
												</PanelRow>
											</SubscriptionPanel>
										</PanelWrapper>
									))}
									{!!gift_redemption_lookup && (
										<GiftRedemptionPanel
											{...{
												gift_redemption_lookup,
												redemption_code_prefill,
											}}
										/>
									)}
								</SubscriptionsWrapper>
							</div>
						) : (
							<NoUserWrapper>
								<Text.Heading
									color={styles.colors.whiteGray}
									type='h5'
									variant='bold'
									style={{ marginBottom: '24px' }}
								>
									No User found!
								</Text.Heading>
								<Text.Caption
									color={styles.colors.whiteGray}
									style={{ marginBottom: '24px' }}
								>
									This link is no longer valid. Maybe you're
									here by accident, or maybe the link has
									expired!
								</Text.Caption>
								<Text.NavLink
									color={styles.colors.whiteGray}
									style={{
										textDecoration: 'underline',
										textDecorationColor:
											styles.colors.whiteGray,
									}}
									href='/subscribe'
								>
									Create a New Magic Link
								</Text.NavLink>
							</NoUserWrapper>
						)}
					</Col>
				</Row>
			</StyeldContainer>
		</ScreenWrapper>
	)
}
