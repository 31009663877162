import React from 'react'
import styled from 'styled-components'
import Header from '../shared/Header'
import Footer from '../shared/Footer'

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 125px;
	max-width: 100vw;
	@media only screen and (max-width: 992px) {
		margin-left: 0
	}
`

/**
 *
 * @param {{isWebView?:boolean, children:any, style?:React.CSSProperties, footerStyle?:React.CSSProperties, noFooter?:boolean}} props
 * @returns
 */
export default function ScreenWrapper({
	isWebView,
	children,
	style,
	footerStyle,
	noFooter,
}) {
	return (
		<Wrapper {...{ style }}>
			{!Boolean(isWebView) && <Header />}
			{children}
			{!Boolean(isWebView) && !noFooter && (
				<Footer style={footerStyle} />
			)}
		</Wrapper>
	)
}
