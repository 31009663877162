import React from 'react'
import { Image } from 'react-bootstrap'
import styled from 'styled-components'
import { apple_button, android_button } from '../../utility/icons'

const apple = 'https://apps.apple.com/us/app/tezlab/id1239581716'
const android =
	'https://play.google.com/store/apps/details?id=com.happyfuncorp.tesla'
const hrefs = {
	apple,
	android,
}
const icons = {
	apple: apple_button,
	android: android_button,
}

const Button = styled.a`
	width: 118px;
	max-width: 118px;
	max-height: 40px;
	height: 40px;
	margin: 4px;
`

/**
 *
 * @param {{type?: 'apple' | 'android', style:React.CSSProperties}} props
 * @returns
 */
export default function StoreButton({ type = 'apple', style }) {
	return (
		<Button
			href={hrefs[type]}
			target='_blank'
			rel='noreferrer'
			{...{ style }}
		>
			<Image fluid src={icons[type]} />
		</Button>
	)
}
