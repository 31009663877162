import React, { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import styled, { css, keyframes } from 'styled-components'
import validator from 'validator'
import { apiPost } from '../../utility/api'
import ScreenWrapper from '../shared/ScreenWrapper'
import Text from '../shared/Text'
import Input from '../shared/Input'
import styles from '../../utility/styles'
import Button from '../shared/Button'
import { sanitizeData } from '../../utility/helpers'
import {
	FoundersIcon,
	ProIconGold,
	ProIconBlack,
	FoundersIconBlack,
} from '../../utility/icons'

const StyledContainer = styled(Container)`
	margin-top: 72px;
	margin-bottom: 72px;
`

const FormWrapper = styled.div`
	padding: 48px;
	border: 1px solid ${styles.colors.darkGray};
	border-radius: 4px;
`

const fadeIn = keyframes`
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
`

const fadeInAnimation = css`
	animation: ${fadeIn} 0.5s ease-in-out forwards;
`

const StepWrapper = styled.div`
	display: flex;
	align-items: center;
	margin 24px auto;
	width: fit-content;
	${fadeInAnimation};
`

const StyledRow = styled(Row)`
	${fadeInAnimation};
`

const Step = styled.div`
	background-color: ${styles.colors.founders};
	padding: 4px 8px;
	border-radius: 2px;
	margin-right: 8px;
`

const GiftListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 64px;
	${fadeInAnimation};
`

const GiftOption = styled(Button)`
	display: flex;
	background-color: ${({ selected }) =>
		selected ? styles.colors.founders : styles.colors.darkGray};
	margin: ${({ selected }) => (selected ? '0 0 4px 0' : '0 8px 4px 8px')};
	border-radius: 4px;
	padding: 12px 24px;
`

const GiftColumn = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
`

const InputCol = styled(Col)`
	@media only screen and ${styles.breakpoints.mobileL} {
		margin-bottom: 4px;
	}
`

const NumLeft = styled(Text.Tiny)`
	position: absolute;
	right: 28px;
	bottom: 20px;
`

const onSubmit = e => e?.preventDefault()

const Icon = ({ image, selected }) => {
	if (image === 'founders_series') {
		if (selected)
			return <FoundersIconBlack style={{ marginRight: '12px' }} />
		return <FoundersIcon style={{ marginRight: '12px' }} />
	}
	if (selected) return <ProIconBlack style={{ marginRight: '12px' }} />
	return <ProIconGold style={{ marginRight: '12px' }} />
}

const CheckIcon = ({ selected }) => {
	if (selected)
		return (
			<svg
				width='32'
				height='32'
				viewBox='0 0 32 32'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='16' cy='16' r='12' fill='#E6BC2A' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M15.9987 30.6666C7.89852 30.6666 1.33203 24.1001 1.33203 15.9999C1.33203 7.89974 7.89852 1.33325 15.9987 1.33325C24.0989 1.33325 30.6654 7.89974 30.6654 15.9999C30.6654 24.1001 24.0989 30.6666 15.9987 30.6666ZM11.0559 18.2761C10.5352 17.7554 10.5352 16.9111 11.0559 16.3904C11.5766 15.8697 12.4208 15.8697 12.9415 16.3904L14.6654 18.1143L20.3892 12.3904C20.9099 11.8697 21.7541 11.8697 22.2748 12.3904C22.7955 12.9111 22.7955 13.7554 22.2748 14.2761L15.6082 20.9427C15.0875 21.4634 14.2433 21.4634 13.7226 20.9427L11.0559 18.2761Z'
					fill='#2B2401'
				/>
			</svg>
		)
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.33203 15.9999C1.33203 24.1001 7.89852 30.6666 15.9987 30.6666C24.0989 30.6666 30.6654 24.1001 30.6654 15.9999C30.6654 7.89974 24.0989 1.33325 15.9987 1.33325C7.89852 1.33325 1.33203 7.89974 1.33203 15.9999ZM27.9987 15.9999C27.9987 22.6273 22.6261 27.9999 15.9987 27.9999C9.37128 27.9999 3.9987 22.6273 3.9987 15.9999C3.9987 9.3725 9.37128 3.99992 15.9987 3.99992C22.6261 3.99992 27.9987 9.3725 27.9987 15.9999Z'
				fill='#9EA6B7'
			/>
		</svg>
	)
}

export default function ({ data }) {
	const [step, setStep] = useState(1)
	const [selected, setSelected] = useState(0)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [from_name, setFromName] = useState('')
	const [from_email, setFromEmail] = useState('')
	const [to_name, setToName] = useState('')
	const [to_email, setToEmail] = useState('')
	const [gift_message, setGiftMessage] = useState('')
	const [gift, setGift] = useState({})

	// onchanges
	const onFromNameChange = useCallback(
		({ target }) => setFromName(target?.value ?? ''),
		[]
	)

	const onFromEmailChange = useCallback(
		({ target }) => setFromEmail(target?.value ?? ''),
		[]
	)

	const onNameChange = useCallback(
		({ target }) => setToName(target?.value ?? ''),
		[]
	)

	const onEmailChange = useCallback(
		({ target }) => setToEmail(target?.value ?? ''),
		[]
	)

	const onMessageChange = useCallback(
		({ target }) =>
			setGiftMessage(msg =>
				target?.value?.length <= 250 ? target?.value ?? '' : msg
			),
		[]
	)

	const advanceStep = (action_items = []) => {
		if (step === 1) {
			if (action_items.includes('from_email')) return
			return setStep(2)
		}
		if (step === 2) {
			if (action_items.includes('to_email')) return
			return setStep(3)
		}
		return setStep(4)
	}

	// submits

	const onContinue = useCallback(async () => {
		try {
			setLoading(true)
			setError('')
			const body = sanitizeData({
				from_email,
				from_name,
				to_email,
				to_name,
				gift_message,
			})
			const { data, message } = await apiPost('/gift', body)
			if (message) throw message
			if (!data) throw 'No Data'
			advanceStep(data?.action_items)
			setGift(data)
			setLoading(false)
			const {
				from_email: res_from_email,
				from_name: res_from_name,
				to_email: res_to_email,
				to_name: res_to_name,
				gift_message: res_gift_message,
			} = sanitizeData(data)
			if (res_from_email) setFromEmail(res_from_email)
			if (res_from_name) setFromName(res_from_name)
			if (res_to_email) setToEmail(res_to_email)
			if (res_to_name) setToName(res_to_name)
			if (res_gift_message) setGiftMessage(res_gift_message)
		} catch (error) {
			setLoading(false)
			setError(typeof error === 'string' ? error : 'Something Went Wrong')
		}
	}, [from_email, from_name, to_email, to_name, gift_message])

	const onCheckOut = useCallback(async () => {
		if (gift.redirect_to) return (window.location = gift.redirect_to)
		try {
			setLoading(true)
			setError('')
			const body = sanitizeData({
				from_email,
				from_name,
				to_email,
				to_name,
				gift_message,
				gift_type: gift.available_gift_types[selected]?.unique_id,
			})
			const { data, success, message } = await apiPost('/gift', body)
			if (message) throw message
			if (!success || !data) throw 'error with checkout'
			setGift(data)
			setLoading(false)
			if (data.redirect_to) return (window.location = data.redirect_to)
		} catch (error) {
			setLoading(false)
			setError(typeof error === 'string' ? error : 'Something Went Wrong')
		}
	}, [from_email, from_name, to_email, to_name, gift_message, gift, selected])

	useEffect(() => {
		if (data) {
			const sanitized = sanitizeData(data)
			setGift(sanitized)
			setFromEmail(sanitized.from_email ?? '')
			setFromName(sanitized.from_name ?? '')
			setToEmail(sanitized.to_email ?? '')
			setToName(sanitized.to_name ?? '')
			setGiftMessage(sanitized.gift_message ?? '')
			// update step
			if (sanitized.from_name && sanitized.from_email) {
				if (
					sanitized.to_name &&
					sanitized.to_email &&
					sanitized.gift_message &&
					sanitized.available_gift_types?.length
				)
					return setStep(3)
				setStep(2)
			}
		}
	}, [data])

	useEffect(() => {
		if (step === 3) {
			// if email changes after step 3, go back to step 2
			setGift({})
			setStep(2)
			setError('')
		}
	}, [to_email])

	return (
		<ScreenWrapper>
			<StyledContainer>
				<Row>
					<Col>
						<Text.Heading
							variant='bold'
							color={styles.colors.whiteGray}
							style={{ textAlign: 'center' }}
						>
							Gift a TezLab Pro Membership
						</Text.Heading>
					</Col>
				</Row>
				{step >= 1 && (
					<>
						<StepWrapper>
							<Step>
								<Text.Tiny
									variant='bold'
									color={styles.colors.background}
								>
									STEP 1
								</Text.Tiny>
							</Step>
							<Text.Heading type='h6'>
								Who is this gift from?
							</Text.Heading>
						</StepWrapper>
						<StyledRow>
							<Col>
								<FormWrapper>
									<Form {...{ onSubmit }}>
										<Row style={{ marginBottom: '24px' }}>
											<InputCol sm={12} md={6}>
												<Input
													id='your_name'
													label='Your Name'
													value={from_name}
													onChange={onFromNameChange}
													type='text'
												/>
											</InputCol>
											<InputCol sm={12} md={6}>
												<Input
													id='your_email'
													label='Your Email'
													value={from_email}
													onChange={onFromEmailChange}
													type='email'
												/>
											</InputCol>
										</Row>
										{step === 1 && (
											<Button
												size='lg'
												style={{
													width: 'fit-content',
													margin: '0 auto',
												}}
												onClick={onContinue}
												disabled={
													!from_name ||
													!validator.isEmail(
														from_email
													)
												}
												{...{ loading }}
											>
												<Text.Body
													variant='bold'
													style={{
														marginRight: '12px',
													}}
												>
													Continue
												</Text.Body>
												<svg
													width='16'
													height='16'
													viewBox='0 0 16 16'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M10.7813 8.66667L8.39464 11.06L9.33463 12L13.3346 8L9.33463 4L8.39464 4.94L10.7813 7.33333H2.66797V8.66667H10.7813Z'
														fill={
															styles.colors.white
														}
													/>
												</svg>
											</Button>
										)}
									</Form>
								</FormWrapper>
							</Col>
						</StyledRow>
					</>
				)}
				{step > 1 && (
					<>
						<StepWrapper>
							<Step>
								<Text.Tiny
									variant='bold'
									color={styles.colors.background}
								>
									STEP 2
								</Text.Tiny>
							</Step>
							<Text.Heading type='h6'>
								Who is this gift for?
							</Text.Heading>
						</StepWrapper>
						<StyledRow>
							<Col>
								<FormWrapper>
									<Form {...{ onSubmit }}>
										<Row style={{ marginBottom: '24px' }}>
											<InputCol sm={12} md={6}>
												<Input
													value={to_name}
													onChange={onNameChange}
													label='Their Full Name'
													type='text'
													id='to_name'
												/>
											</InputCol>
											<InputCol sm={12} md={6}>
												<Input
													value={to_email}
													onChange={onEmailChange}
													label='Their Email'
													type='email'
													id='to_email'
												/>
											</InputCol>
										</Row>
										<Row style={{ marginBottom: '24px' }}>
											<InputCol
												sm={12}
												style={{ position: 'relative' }}
											>
												<Input
													value={gift_message}
													onChange={onMessageChange}
													placeholder='Gift Message...'
													type='text'
													as='textarea'
													style={{ width: '100%' }}
													inputStyle={{
														width: '100%',
														padding: '12px',
													}}
													id='message'
												/>
												<NumLeft
													color={
														styles.colors.lightGray
													}
												>
													{gift_message?.length}/250
												</NumLeft>
											</InputCol>
										</Row>
										{step === 2 && (
											<Button
												size='lg'
												style={{
													width: 'fit-content',
													margin: '0 auto',
												}}
												onClick={onContinue}
												disabled={
													!gift_message ||
													!validator.isEmail(
														to_email
													) ||
													!to_name
												}
												{...{ loading }}
											>
												<Text.Body
													variant='bold'
													style={{
														marginRight: '12px',
													}}
												>
													Continue
												</Text.Body>
												<svg
													width='16'
													height='16'
													viewBox='0 0 16 16'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M10.7813 8.66667L8.39464 11.06L9.33463 12L13.3346 8L9.33463 4L8.39464 4.94L10.7813 7.33333H2.66797V8.66667H10.7813Z'
														fill={
															styles.colors.white
														}
													/>
												</svg>
											</Button>
										)}
									</Form>
								</FormWrapper>
							</Col>
						</StyledRow>
					</>
				)}
				{step === 3 && (
					<>
						<StepWrapper>
							<Step>
								<Text.Tiny
									variant='bold'
									color={styles.colors.background}
								>
									STEP 3
								</Text.Tiny>
							</Step>
							<Text.Heading type='h6'>Select a Gift</Text.Heading>
						</StepWrapper>
						<GiftListWrapper>
							{gift.available_gift_types?.map(
								(
									{
										key,
										price_val,
										subtitle,
										title,
										unique_id,
									},
									index
								) => (
									<GiftOption
										key={unique_id}
										selected={selected === index}
										onClick={() => setSelected(index)}
									>
										<Icon
											image={key}
											selected={selected === index}
										/>
										<GiftColumn>
											<Text.Heading
												type='h4'
												variant='bold'
												color={
													selected === index
														? styles.colors
																.background
														: styles.colors.white
												}
												style={{ textAlign: 'left' }}
											>
												{title}
											</Text.Heading>
											<Text.Body
												color={
													selected === index
														? styles.colors
																.background
														: styles.colors.white
												}
											>
												{subtitle}
											</Text.Body>
										</GiftColumn>
										<CheckIcon
											selected={selected === index}
										/>
									</GiftOption>
								)
							)}
							{!gift.available_gift_types?.length && (
								<Text.Body
									variant='bold'
									style={{ textAlign: 'center' }}
								>
									No gift options available
								</Text.Body>
							)}
						</GiftListWrapper>
						<Button
							size='lg'
							style={{
								width: 'fit-content',
								margin: '0 auto 64px auto',
							}}
							onClick={onCheckOut}
							disabled={!gift.available_gift_types?.length}
							{...{ loading }}
						>
							<Text.Body
								variant='bold'
								style={{
									marginRight: '12px',
								}}
							>
								Check Out
							</Text.Body>
							<svg
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M10.7813 8.66667L8.39464 11.06L9.33463 12L13.3346 8L9.33463 4L8.39464 4.94L10.7813 7.33333H2.66797V8.66667H10.7813Z'
									fill={styles.colors.white}
								/>
							</svg>
						</Button>
					</>
				)}
				<Text.Caption
					color={styles.colors.alert}
					style={{ textAlign: 'center', marginTop: '12px' }}
				>
					{error}
				</Text.Caption>
			</StyledContainer>
		</ScreenWrapper>
	)
}
