import React from 'react';
import styled from 'styled-components';
import Button from '../../shared/Button';
import Text from '../../shared/Text';
import { DownTick } from '../../../utility/icons';
import styles from '../../../utility/styles';

const RelativeDiv = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    juistify-content: center;
`;

const MenuWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    position: absolute;
    transform: translateY(-100%);
    height: ${({ showMore }) => showMore ? 'auto' : '0'};
    overflow: hidden;
`;

const MenuArrow = styled.div`
    height: 0;
    width: 0;
    border-top: 9px solid ${styles.colors.darkGray};
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 9px solid transparent;
`;

const MenuBody = styled.div`
    padding: 24px;
    background-color: ${styles.colors.darkGray};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

export default function DropUp({ buttonList = [] }) {
    const [showMore, setShowMore] = React.useState(null);
    const toggleMore = React.useCallback(() => setShowMore(bool => !bool), [setShowMore])
    return (
        <div>
            <RelativeDiv>
                <MenuWrapper {...{ showMore }}>
                    <MenuBody>
                        {buttonList.map((button, i) => (
                            <Button key={button.text} onClick={button.onClick} variant='secondary_alt' style={{ marginBottom: i < buttonList.length - 1 ? '24px' : '0' }}>
                                <Text.Heading variant='bold' type='h4'>{button.text}</Text.Heading>
                            </Button>
                        ))}
                    </MenuBody>
                    <MenuArrow />
                </MenuWrapper>
            </RelativeDiv>
            <Button
                style={{ margin: '0 4px', paddingLeft: '24px', paddingRight: '24px' }}
                variant='secondary'
                onClick={toggleMore}
            >
                <Text.Heading variant='bold' type='h4' style={{ marginRight: '4px' }}>More</Text.Heading>
                <DownTick />
            </Button>
        </div>
    )
}