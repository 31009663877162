import React from 'react'
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Progress from './Progress';
import Drive from './Drive';
import Button from '../../shared/Button';
import Text from '../../shared/Text';
import { Phone, Charging } from '../../../utility/icons';
import styles from '../../../utility/styles';
import ClimateFooter from './ClimateFooter';
import { useStandardState } from '../../../utility/api';
import { post } from '../../../utility/api';

const Wrapper = styled.div`
    margin: 0 80px 80px 80px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 15vh;
`;

const DriveWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const DriveContainer = styled.div`
    background-color: ${styles.colors.darkGray};
    padding: 24px;
    border-radius: 4px;
    margin-bottom: 12px;
`;

const DriveFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default function Charge({ data }) {
    
    const [{ error, data: fetchedData, isFetching }, { startFetching, recieveData, recieveError }] = useStandardState();

    const openInApp = React.useCallback(async () => {
        if (!data?.last_drive_link_action) return;
        startFetching();
        const { success } = await post(data.last_drive_link_action);
        if (!success) return recieveError(error)
        if (success) recieveData({success})
    }, [data])

    return (
        <Wrapper>
            <Row style={{ marginBottom: '96px' }}>
                <Col xs={5}>
                    <Progress.Vertical
                        text={data.real_world_range_string}
                        percent={data.battery_level}
                        isCharging
                        title='Est. Real World Range'
                        Icon={Charging}
                    />
                </Col>
                <Col xs={7}>
                    <DriveWrapper>
                        <DriveContainer>
                            <Text.Heading variant='bold' type='h6'>Last Drive</Text.Heading>
                            <Drive data={data.last_drive_detail} />
                        </DriveContainer>
                        <DriveFooter>
                            <Button hideShadow disabled={isFetching} loading={isFetching} onClick={openInApp} style={{ backgroundColor: 'transparent' }}>
                                <Phone style={{marginRight: '8px'}}/>
                                <Text.Body style={{ color: styles.colors.lightGray }}>{!fetchedData ? 'View details in app' : fetchedData.success ? 'Notification Sent' : 'Hmm Try that again'}</Text.Body>
                            </Button>
                        </DriveFooter>
                    </DriveWrapper>
                </Col>
            </Row>
            <ClimateFooter 
                pro={data.pro_user}
                recent={data.climate_options?.recent ?? []}
                options={data.climate_options?.options ?? []}
                expanded={data.climate_options?.expanded ?? []}
                cancelAction={data.climate_cancel_action}
                climateScheduledTs={data.climate_scheduled_ts}
                vehicleName={data.vehicle_name}
                timezone={data.timezone}
            />
        </Wrapper>
    )
}