import React from 'react';


export default function WebDashLanding({ }) {

  const [sessionId, setSessionId] = React.useState(()=>{
    return JSON.parse( localStorage.getItem("wdsid") ) || "new";
  });

  window.location.replace(`${window.location.href}/${sessionId}`);

}