import React from 'react';
import styled from 'styled-components';
import styles from '../../../utility/styles';
import { useCallback } from 'react';
import { formatDate } from '../../../utility/helpers';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
`

const Row = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1;
    position: relative;
`

const IconButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    border-radius: 1px;
    background-color: ${styles.colors.transparent};
    border: none;
    font-family: ${styles.fonts.nunito};
    font-weight: 600;
    color: ${styles.colors.lightGray};
    transition: all 0.2s;
    &:hover {
        color: ${styles.colors.white};
    }
    @media only screen and (max-width: 768px) {
        visibility: hidden;
    }
`

const DateButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${styles.colors.transparent};
    border: none;
    font-family: ${styles.fonts.nunito};
    font-weight: 600;
    color: ${styles.colors.lightGray};
    transition: all 0.2s;
    &:hover {
        color: ${styles.colors.white};
    }
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
    @media only screen and (max-width: 480px) {
        font-size: 9px;
    }
`

const Line = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    height: 2px;
    background-color: ${styles.colors.darkGray};
    @media only screen and (max-width: 768px) {
        left: 24px;
        right: 24px;
    }
`

const Circle = styled.div`
    width: ${({ isActive }) => isActive ? 20 : 16}px;
    height: ${({ isActive }) => isActive ? 20 : 16}px;
    border-radius: 50%;
    margin-bottom: 4px;
    border: ${({ isActive }) => isActive ? 3 : 2}px solid ${({ isActive }) => isActive ? styles.colors.white : styles.colors.darkGray}};
    background-color: ${({ isActive }) => isActive ? styles.colors.whiteGray : styles.colors.background}};
    z-index: 100;
    transition: all 0.2s;
`

/**
 * 
 * @param {{
 *   onClick: (index:number) => void,
 *   activeSegment:number,
 *   days: Date[]
 * }} props
 * @returns 
 */
export default function DatePicker({ onClick, activeSegment, days = [] }) {
    const onPrevious = useCallback(() => onClick(activeSegment > 0 ? activeSegment - 1 : 0), [activeSegment, onClick])
    const onNext = useCallback(() => onClick(activeSegment < days.length - 1 ? activeSegment + 1 : days.length - 1), [activeSegment, days.length, onClick])
    return (
        <Wrapper>
            {days.length > 1 && (
                <IconButton onClick={onPrevious}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M8.78809 12L14.7981 18.01L16.2121 16.596L11.6121 11.996L16.2121 7.39599L14.7981 5.98999L8.78809 12Z" fill="white" />
                    </svg>
                </IconButton>
            )}
            <Row>
                <Line />
                {days.map((day, i) => (
                    <DateButton
                        key={day}
                        onClick={() => onClick(i)}
                        style={{
                            color: activeSegment === i ? styles.colors.blue : styles.colors.lightGray,
                            fontWeight: 800
                        }}
                    >
                        <Circle isActive={activeSegment === i} />
                        {formatDate(day)}
                    </DateButton>
                ))}
            </Row>
            {days.length > 1 && (
                <IconButton onClick={onNext}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M16.2131 12L10.2021 5.98999L8.78809 7.40399L13.3881 12.004L8.78809 16.597L10.2021 18.011L16.2131 12Z" fill="white" />
                    </svg>
                </IconButton>
            )}
        </Wrapper>
    )
}