import React from 'react';
import Text from '../shared/Text';
import ScreenWrapper from '../shared/ScreenWrapper';
import { Container } from 'react-bootstrap';

export default function WebDash({ }) {
    return (
        <ScreenWrapper>
            <Container style={{paddingHorizontal: "2rem"}}>
                <Text.Heading style={{ textAlign: 'center', marginBottom: "2rem" }}>The Web Dash has retired. 🥂</Text.Heading>
                <Text.Para style={{ textAlign: 'center', marginBottom: "1rem" }}>The web dash was something we built in the very early days of TezLab. After focusing our efforts on the mobile app for the last few years, the web dash has now been retired. We are confident it's enjoying its time getting some much needed R&R on a beach somewhere. 🏖</Text.Para>
                <Text.Para style={{ textAlign: 'center' }}>We're working on an updated web dash for the near future. In the meantime, did you know that we've built an awesome live drive view into the TezLab mobile app? Simply tap the blue banner at the top of the screen once you put your car into drive. Pop your phone in the charging cradle and enjoy live updating drive metrics hands-free.</Text.Para>

            </Container>
            
        </ScreenWrapper>
    )
}