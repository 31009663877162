import React from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../../shared/Text';
import Button from '../../shared/Button';
import styles from '../../../utility/styles';
import { formatDate } from '../../../utility/helpers';
import Models from './Models';
import Locations from './Locations';

const StyledRow = styled(Row)`
    padding-bottom: 24px;
`

const Separator = styled.hr`
    border: 1px solid ${styles.colors.darkGray};
    margin: 24px 0;
`

const MyRow = styled.div`
    display: flex;
    justify-content: ${({ justify }) => justify || 'flex-start'};
    align-items: center;
    margin-bottom: ${({ margin }) => margin || '4px'};
`

const StyledButton = styled(Button)`
    align-self: flex-start;
    @media only screen and (max-width: 768px) {
        font-size: 12px;
        padding: 4px 8px;
    }
`

/**
 * 
 * @param {{
 *   data: {
 *     version:string, 
 *     percent_of_fleet:number, 
 *     is_latest:boolean,
 *     first_detected_at:Date,
 *     href:string,
 *     install_distribution_by_model:[{model_name:string,pct:number}],
 *     install_distribution_location:[{country:string,state:string,pct:number}],
 *     installed_on_models:string[],
 *     id:number,
 *   }[],
 *   make_friendly_name: string
 * }} props
 * @returns 
 */
export default function LatestList({ data = [], make_friendly_name }) {

    if (!data.length) return (
        <Text.Body variant='bold' style={{ textAlign: 'center' }}>No Recent Versions for {make_friendly_name}</Text.Body>
    )

    return (
        <React.Fragment>
            {data.map(({ version, first_detected_at, percent_of_fleet, is_latest, install_distribution_by_model, install_distribution_location, href }) => (
                <StyledRow key={version}>
                    <Col xs={12} lg={4} className=' d-flex flex-column'>
                        <MyRow justify='flex-start'>
                            <Text.Body variant='bold' >VER. {version}</Text.Body>
                            {is_latest && <Text.Tiny variant='bold' color={styles.colors.background} style={{ padding: '4px', backgroundColor: styles.colors.founders, marginLeft: '8px' }}>LATEST</Text.Tiny>}
                        </MyRow>
                        {!!first_detected_at ? (
                            <Text.Tiny variant='bold' color={styles.colors.lightGray}>First Detected {formatDate(first_detected_at)}</Text.Tiny>
                        ) : (
                            <Text.Tiny variant='bold' color={styles.colors.lightGray}>Not Fully Installed Yet</Text.Tiny>
                        )}
                        <Text.Heading type='mega' variant='bold'>{percent_of_fleet}%</Text.Heading>
                        <StyledButton forwardedAs='a' variant='secondary' pill {...{ href }}>
                            View Details{' '}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M10.7798 8.66667L8.39317 11.06L9.33317 12L13.3332 8L9.33317 4L8.39317 4.94L10.7798 7.33333H2.6665V8.66667H10.7798Z" fill="white" />
                            </svg>
                        </StyledButton>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Models models={install_distribution_by_model} />
                    </Col>
                    <Col xs={12} lg={4}>
                        <Locations locations={install_distribution_location} />
                    </Col>
                    <Separator />
                </StyledRow>
            ))}
        </React.Fragment>
    )
}