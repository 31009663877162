import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Text from '../shared/Text';
import ScreenWrapper from '../shared/ScreenWrapper';

const StyledContainer = styled(Container)`
    margin-top: 72px;
    margin-bottom: 72px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default function Error404({ }) {
    return (
        <ScreenWrapper>
            <StyledContainer>
                <Text.Huge tablet='h1' style={{ textAlign: 'center', marginBottom: '48px' }}>404 - Not Found</Text.Huge>
                <Text.Body style={{ textAlign: 'center' }}>Hmm...The page you requested doesn't seem to exist. Please double check and give it another try.</Text.Body>
            </StyledContainer>
        </ScreenWrapper>
    )
}