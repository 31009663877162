import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Text from './Text'
import {
	LogoHoriz as Logo,
	AppLogo,
	TeamLogo,
	FoundersLogo,
	FaqLogo,
	hfc_logo,
	TeslaLogo,
	RivianIcon,
	TeslaIcon,
} from '../../utility/icons'
import styles from '../../utility/styles'

const StyledCaption = styled(Text.Caption)`
	text-align: center;
	color: ${styles.colors.lightGray};
	margin-bottom: 22px;
	justify-content: center;
	align-items: center;
`

const StyledTiny = styled(Text.Tiny)`
	text-align: center;
	color: ${styles.colors.lightGray};
`

const StyledLink = styled.a`
	color: ${styles.colors.lightGray};
`

const ButtonLink = styled.div`
	padding: 8px 12px;
	border-radius: 8px;
	border: 1px solid ${styles.colors.lightGray};
`

/**
 *
 * @param {{style?:React.CSSProperties}} props
 * @returns
 */
export default function Footer({ style = {} }) {
	return (
		<Container fluid style={{ marginBottom: '87px', ...style }}>
			<Row>
				<Col
					className='d-flex justify-content-center'
					style={{ marginBottom: '22px' }}
				>
					<StyledLink href='/'>
						<Logo />
					</StyledLink>
				</Col>
			</Row>
			<Row
				className='d-flex justify-content-center'
				style={{ marginBottom: '22px' }}
			>
				<Col
					md={6}
					className='px-0 mx-0 d-flex justify-content-center'
					style={{ flex: 0 }}
				>
					<Text.NavLink
						color={styles.colors.lightGray}
						horizontal
						href='/app'
						iconComponent={
							<AppLogo fill={styles.colors.lightGray} />
						}
					>
						The App
					</Text.NavLink>
					<Text.NavLink
						color={styles.colors.lightGray}
						horizontal
						href='/founders'
						iconComponent={
							<FoundersLogo fill={styles.colors.lightGray} />
						}
					>
						Founders Series
					</Text.NavLink>
				</Col>
				<Col
					md={6}
					className='px-0 mx-0 d-flex justify-content-center'
					style={{ flex: 0 }}
				>
					<Text.NavLink
						color={styles.colors.lightGray}
						horizontal
						href='/team'
						iconComponent={
							<TeamLogo fill={styles.colors.lightGray} />
						}
					>
						Team
					</Text.NavLink>
					<Text.NavLink
						color={styles.colors.lightGray}
						horizontal
						href='https://support.tezlabapp.com'
						iconComponent={
							<FaqLogo fill={styles.colors.lightGray} />
						}
					>
						Support
					</Text.NavLink>
					{/* <Text.NavLink
                        href='/mission'
                        iconComponent={<MissionLogo fill={styles.colors.lightGray} />}
                    >
                        Mission
                    </Text.NavLink> */}
					{/* <Text.NavLink
                        href='/stats'
                        iconComponent={<StatsLogo fill={styles.colors.lightGray} />}
                    >
                        Stats
                    </Text.NavLink> */}
				</Col>
			</Row>
			<Row
				className='d-flex justify-content-center'
				style={{ marginBottom: '22px' }}
			>
				<Col className='d-flex align-items-center justify-content-center'>
					<Text.Caption color={styles.colors.lightGray}>Software Tracker</Text.Caption>
					<Text.NavLink iconComponent={<RivianIcon fill={styles.colors.lightGray} />} href='/software-tracker/rivian' horizontal color={styles.colors.lightGray} style={{ border: `1px solid ${styles.colors.lightGray}`, borderRadius: '24px', margin: '0 8px' }}>
						Rivian
					</Text.NavLink>
					<Text.NavLink iconComponent={<TeslaIcon fill={styles.colors.lightGray} />} href='/software-tracker/tesla' horizontal color={styles.colors.lightGray} style={{ border: `1px solid ${styles.colors.lightGray}`, borderRadius: '24px' }}>
						Tesla
					</Text.NavLink>
				</Col>
			</Row>
			{window.location.pathname !== '/forgot_password' && (
				<StyledCaption>
					Have you forgotten your TezLab password? If so,{' '}
					<StyledLink
						href='/forgot_password'
						style={{ textDecoration: 'underline' }}
					>
						you can change it!
					</StyledLink>
				</StyledCaption>
			)}
			{/* <StyledCaption>
				Proudly incubated at{' '}
				<StyledLink href='https://www.happyfuncorp.com' target='_blank'>
					<Image
						src={hfc_logo}
						style={{ height: '14px', marginTop: '-2px' }}
					/>
				</StyledLink>
				.
			</StyledCaption> */}
			<StyledTiny>
				<StyledLink href='/terms'>Terms</StyledLink> |{' '}
				<StyledLink href='/privacy'>Privacy</StyledLink> |{' '}
				<StyledLink href='/faq'>Help</StyledLink>
			</StyledTiny>

			<StyledTiny>
				Copyright © {new Date().getFullYear()} TezLab
			</StyledTiny>
		</Container>
	)
}
