import React, { SVGProps } from 'react';
import styled, { keyframes, css } from 'styled-components';
import styles from '../../utility/styles';

const lengthAnimation = size => keyframes`
    0% {
        stroke-dashoffset: ${0.5 * size};
        transform: rotate(0deg);
    }
    50% {
        stroke-dashoffset: ${Math.PI * size};
        transform: rotate(720deg);
    }
    100% {
        stroke-dashoffset: ${0.5 * size};
        transform: rotate(1080deg);
    }
`;

const animation = props => 
    css`
        ${lengthAnimation(props.size)} ${props.duration / 1000}s linear infinite;
    `;

const Circle = styled.circle`
    fill: transparent;
    stroke: ${({color}) => color};
    stroke-width: ${({size}) => `${Math.round(size / 10)}`};
    stroke-linecap: round;
    transform-origin: 50%;
    animation: ${animation};
`;


/**
 * 
 * @param {{size?:number, color?:string, duration?:number}&SVGProps} props 
 */
export default function Spinner({ size = 40, color = styles.colors.lightGray, duration = 3000, ...props }) {
    const r = React.useMemo(() => (size - size / 5)/2, [size])
    return (
        <svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} x='0px' y='0px' {...props}>
            <Circle strokeDasharray={Math.PI * 2 * r} cx={size/2} cy={size/2} {...{ size, color, r, duration }} />
        </svg>
    )
}