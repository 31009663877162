import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Text from "./Text";
import { Check, NoCheck } from "../../utility/icons";
import styles from "../../utility/styles";

const Divider = styled.hr`
    color: ${({color}) => color};
    height: ${({height}) => height}px;
    margin: 0 41px 16px 41px;
    @media only screen and ${styles.breakpoints.mobileL} {
        margin: 0 9px 16px 9px;
    }
`;

const BlueCard = styled.div`
    display: flex;
    flex: 0;
    background: ${styles.colors.blue};
    padding: 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 8px 8px;
`;

const StyledRow = styled(Row)`
    margin: 0 41px 16px 41px;
    @media only screen and ${styles.breakpoints.mobileL} {
        margin: 0 9px 16px 9px;
    }
`;

const Heading = () => {
    return (
        <StyledRow className='g-0'>
            <Col xs={2} className='d-flex align-items-start justify-content-center'>
                <Text.Heading variant='bold' type='h5'>Regular</Text.Heading>
            </Col>
            <Col xs={8} className='d-flex align-items-center justify-content-center' />
            <Col xs={2} className='d-flex align-items-center justify-content-end'>
                <Text.Heading variant='bold' type='h5' style={{ color: styles.colors.blue }}>TezLab Pro</Text.Heading>
            </Col>
        </StyledRow>
    )
}

const Footer = ({ monthly, annual, savings_percent }) => {
    return (
        <>
            <Divider color={styles.colors.darkGray} height={1} style={{marginBottom: '-1px'}} />
            <StyledRow className='g-0'>
                <Col xs={2} className='d-flex align-items-center justify-content-center'>
                    <Text.Heading variant='bold' type='h5'>Free</Text.Heading>
                </Col>
                <Col xs={8} className='d-flex align-items-center justify-content-center' />
                <Col xs={2} className='d-flex align-items-center justify-content-end'>
                    <BlueCard>
                        <Text.Heading variant='bold' type='h5'>{monthly}/month</Text.Heading>
                        <Divider color={styles.colors.white} height={1} />
                        <Text.Tiny style={{ textAlign: 'center' }}>Save {savings_percent} with an annual membership ({annual})</Text.Tiny>
                    </BlueCard>
                </Col>
            </StyledRow>
        </>
    )
}

/**
 * 
 * @param {{text:string, freeType:'free'|'limited'|'7-day'|'none'}} props
 * @returns 
 */
const Body = ({ text, freeType }) => {
    return (
        <>
            <Divider color={styles.colors.darkGray} height={1} />
            <StyledRow className='g-0'>
                <Col xs={2} className='d-flex align-items-center justify-content-center'>
                    {freeType === 'free' && <Check fill={styles.colors.lightGray} />}
                    {freeType === '7-day' && <Text.Tiny style={{ color: styles.colors.mediumGray }}>7 DAYS</Text.Tiny>}
                    {freeType === 'limited' && <Text.Tiny style={{ color: styles.colors.mediumGray }}>LIMITED</Text.Tiny>}
                    {freeType === 'none' && <NoCheck />}
                </Col>
                <Col xs={8} className='d-flex align-items-center justify-content-center'>
                    <Text.Heading type='h6' style={{ textAlign: 'center' }}>{text}</Text.Heading>
                </Col>
                <Col xs={2} className='d-flex align-items-center justify-content-center'>
                    <Check fill={styles.colors.blue} />
                </Col>
            </StyledRow>
        </>
    )
}

export default {
    Heading,
    Footer,
    Body
}