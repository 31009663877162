import React, { useMemo } from 'react';
import Text from '../../shared/Text';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import ScreenWrapper from '../../shared/ScreenWrapper';
import styles from '../../../utility/styles';
import RolloutChart from './RolloutChart';
import Locations from './Locations';
import PreviousVersions from './PreviousVersions';
import { formatDate } from '../../../utility/helpers';

const colors = styles.colors.software_tracker_colors

const StyledContainer = styled(Container)`
    margin-top: 75px;
    margin-bottom: 75px;
    max-width: 100%;
`

const StyledErrorContainer = styled(Container)`
    margin-top: 75px;
    margin-bottom: 75px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Separator = styled.hr`
    border: 1px solid ${styles.colors.darkGray};
    margin: 32px 0;
`

const StyledLinkButton = styled.a`
    display: flex;
    height: 24px;
    padding: 4px 12px 4px 8px;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    gap: 8px;
    color: ${styles.colors.white};
    background-color: ${styles.colors.darkGray};
    border-radius: 100px;
    box-shadow: 4.5px 6px 12px 0px rgba(0, 0, 0, 0.16);
    font-size: 12px;
    line-height: 16px;
    font-weight: 800;
    font-family: ${styles.fonts.nunito};
    margin-bottom: 24px;
`

const MyRow = styled.div`
    display: flex;
    justify-content: ${({ justify }) => justify || 'flex-start'};
    align-items: center;
    margin-bottom: ${({ margin }) => margin || '4px'};
`

const MyCol = styled.div`
    display: flex;
    padding-left: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-left: 1px solid ${styles.colors.darkGray};
    @media only screen and (max-width: 768px) {
        padding-left: 0;
        border-left: none;
        padding-top: 24px;
    }
`

const NotesLink = styled.a`
    color: ${styles.colors.white};
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    margin-left: 12px;
`

const Dot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ color }) => color || styles.colors.white};
    margin-right: 8px;
`

/**
 * 
 * @param {{
 *   data: {
 *     make: string,
 *     make_friendly_name: string,
 *     model: string,
 *     model_friendly_name: string,
 *     id: number,
 *     version: string,
 *     first_detected_at: string,
 *     percent_of_fleet: number,
 *     is_latest: boolean,
 *     installed_on_models: string[],
 *     install_distribution_location: [{ country: string, state: string, pct: number }],
 *     previous_versions: [{ version: string, pct: number }],
 *     rollout_by_date: [{ date: Date, pct: number, models: [{ model: string, pct: number }] }],
*      model_color_array: [{ model: string, color: string, model_friendly_name: string }]
 *   }
 * }} props
 * @returns 
 */
export default function SoftwareDetails({ data }) {
    const {
        make,
        make_friendly_name,
        model,
        model_friendly_name,
        id,
        installed_on_models,
        version,
        first_detected_at,
        percent_of_fleet,
        is_latest,
        install_distribution_location,
        previous_versions,
        rollout_by_date,
        error
    } = data
    const fleetPctText = useMemo(() => `of TezLab ${make_friendly_name} Fleet`, [make_friendly_name])
    const firstDetected = useMemo(() => !!first_detected_at ? `First Detected ${formatDate(first_detected_at)}` : 'Not Fully Installed Yet', [first_detected_at])

    const modelColors = useMemo(() => {
        const obj = {}
        installed_on_models?.forEach((model, i) => {
            obj[model] = colors[i]
        })
        return obj
    }, [installed_on_models])

    if (error) {
        return (
            <ScreenWrapper>
                <StyledErrorContainer>
                    <Text.Heading type='h1' variant='bold'>Error</Text.Heading>
                    <Text.Body>{error}</Text.Body>
                </StyledErrorContainer>
            </ScreenWrapper>
        )
    }

    return (
        <ScreenWrapper>
            <StyledContainer>
                <Row>
                    <Col xs={12} md={6} className='d-flex flex-column'>
                        <StyledLinkButton as='a' href={`/software-tracker/${make}`} variant='secondary' pill>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M5.22033 7.33333L7.60699 4.94L6.66699 4L2.66699 8L6.66699 12L7.60699 11.06L5.22033 8.66667H13.3337V7.33333H5.22033Z" fill={styles.colors.white} />
                            </svg>
                            {' '}Back to Tracker
                        </StyledLinkButton>
                        <MyRow>
                            <Text.Heading type='h1' mobileL='h4' variant='bold' style={{ marginRight: '12px' }}>VER. {version}</Text.Heading>
                            {is_latest && <Text.Tiny variant='bold' style={{ padding: '2px 6px', borderRadius: '2px', backgroundColor: styles.colors.founders }} color={styles.colors.background}>LATEST</Text.Tiny>}
                        </MyRow>
                        {/* <MyRow>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                                <path d="M17.2644 3.50399H9.08035C8.8894 3.50399 8.70626 3.57985 8.57124 3.71488C8.43621 3.8499 8.36035 4.03304 8.36035 4.22399V7.94399C8.36035 8.13495 8.43621 8.31809 8.57124 8.45311C8.70626 8.58814 8.8894 8.66399 9.08035 8.66399H17.2644C17.4553 8.66399 17.6384 8.58814 17.7735 8.45311C17.9085 8.31809 17.9844 8.13495 17.9844 7.94399V4.22399C17.9844 4.03304 17.9085 3.8499 17.7735 3.71488C17.6384 3.57985 17.4553 3.50399 17.2644 3.50399ZM16.5444 7.24799H9.80035V4.94399H16.5444V7.24799Z" fill={styles.colors.white} />
                                <path d="M21.5123 2.4C21.5123 1.82713 21.2847 1.27773 20.8796 0.872647C20.4746 0.467569 19.9252 0.239998 19.3523 0.239998H6.99228C6.41941 0.239998 5.87001 0.467569 5.46493 0.872647C5.05985 1.27773 4.83228 1.82713 4.83228 2.4V3.816H2.64828C2.07541 3.816 1.52601 4.04357 1.12093 4.44865C0.715852 4.85373 0.488281 5.40313 0.488281 5.976V14.88C0.488281 15.6438 0.791709 16.3764 1.33181 16.9165C1.87192 17.4566 2.60446 17.76 3.36828 17.76H18.0803C18.9905 17.76 19.8634 17.3984 20.5071 16.7548C21.1507 16.1112 21.5123 15.2382 21.5123 14.328V2.4ZM4.83228 14.88C4.83228 15.2651 4.6793 15.6344 4.407 15.9067C4.1347 16.179 3.76538 16.332 3.38028 16.332C2.99519 16.332 2.62587 16.179 2.35356 15.9067C2.08126 15.6344 1.92828 15.2651 1.92828 14.88V5.976C1.92828 5.78504 2.00414 5.60191 2.13916 5.46688C2.27419 5.33185 2.45733 5.256 2.64828 5.256H4.83228V14.88ZM20.0963 14.328C20.0963 14.8563 19.8864 15.363 19.5128 15.7366C19.1393 16.1101 18.6326 16.32 18.1043 16.32H5.86428C6.11641 15.882 6.24884 15.3854 6.24828 14.88V2.4C6.24828 2.20904 6.32414 2.02591 6.45917 1.89088C6.59419 1.75585 6.77733 1.68 6.96828 1.68H19.3523C19.5432 1.68 19.7264 1.75585 19.8614 1.89088C19.9964 2.02591 20.0723 2.20904 20.0723 2.4L20.0963 14.328Z" fill={styles.colors.white} />
                                <path d="M17.2644 10.104H9.08035C8.8894 10.104 8.70626 10.1798 8.57124 10.3149C8.43621 10.4499 8.36035 10.633 8.36035 10.824C8.36035 11.0149 8.43621 11.1981 8.57124 11.3331C8.70626 11.4681 8.8894 11.544 9.08035 11.544H17.2644C17.4553 11.544 17.6384 11.4681 17.7735 11.3331C17.9085 11.1981 17.9844 11.0149 17.9844 10.824C17.9844 10.633 17.9085 10.4499 17.7735 10.3149C17.6384 10.1798 17.4553 10.104 17.2644 10.104Z" fill={styles.colors.white} />
                                <path d="M17.2644 12.984H9.08035C8.8894 12.984 8.70626 13.0598 8.57124 13.1949C8.43621 13.3299 8.36035 13.513 8.36035 13.704C8.36035 13.8949 8.43621 14.0781 8.57124 14.2131C8.70626 14.3481 8.8894 14.424 9.08035 14.424H17.2644C17.4553 14.424 17.6384 14.3481 17.7735 14.2131C17.9085 14.0781 17.9844 13.8949 17.9844 13.704C17.9844 13.513 17.9085 13.3299 17.7735 13.1949C17.6384 13.0598 17.4553 12.984 17.2644 12.984Z" fill={styles.colors.white} />
                            </svg>
                            <NotesLink href='#'>Release Notes</NotesLink>
                        </MyRow> */}
                        <Text.Body color={styles.colors.lightGray}>{firstDetected}</Text.Body>
                    </Col>
                    <Col xs={12} md={6} className='d-flex justify-content-md-end'>
                        <MyCol>
                            <Text.Heading type='ultra' variant='bold' style={{ marginBottom: '12px' }} color={styles.colors.blue}>{percent_of_fleet}%</Text.Heading>
                            <Text.Body variant='bold' color={styles.colors.lightGray}>{fleetPctText}</Text.Body>
                        </MyCol>
                    </Col>
                </Row>
                <Separator />
                <Row>
                    <Col md={4}>
                        <Text.Heading type='h3' variant='bold' style={{ marginBottom: '12px' }}>Rollout</Text.Heading>
                        <Text.Body color={styles.colors.lightGray}>How this software was adopted by the fleet based on date and model.</Text.Body>
                        {installed_on_models.map((model, i) => (
                            <MyRow key={model}>
                                <Dot color={modelColors[model]} />
                                <Text.Caption>{model}</Text.Caption>
                            </MyRow>
                        ))}
                    </Col>
                    <Col md={8}>
                        <RolloutChart rollout={rollout_by_date} colors={modelColors} />
                    </Col>
                </Row>
                <Separator />
                <Row>
                    <Col md={4}>
                        <Text.Heading type='h3' variant='bold' style={{ marginBottom: '12px' }}>By Location</Text.Heading>
                        <Text.Body color={styles.colors.lightGray}>Software version based grouped by vehicle location</Text.Body>
                    </Col>
                    <Col md={8} className='d-flex'>
                        <Locations locations={install_distribution_location} />
                    </Col>
                </Row>
                <Separator />
                <Row>
                    <Col md={4}>
                        <Text.Heading type='h3' variant='bold' style={{ marginBottom: '12px' }}>Previously Installed</Text.Heading>
                        <Text.Body color={styles.colors.lightGray}>Which software are people upgrading from</Text.Body>
                    </Col>
                    <Col md={8} className='d-flex'>
                        <PreviousVersions versions={previous_versions} currentVersion={version} />
                    </Col>
                </Row>
            </StyledContainer>
        </ScreenWrapper>
    )
}