import React from "react";
import { Col, Image, ColProps } from "react-bootstrap";
import Text from "./Text";
import styled from "styled-components";
import styles from "../../utility/styles";

const StyledCol = styled(Col)`
    display: flex;
    justify-content: center;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 440px;
    justify-content: space-between;
`;

const ImageWrapper = styled.div`
    background: linear-gradient(${styles.colors.darkGray}, transparent);
    width: 100%;
    height: 142px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    z-index: 100;
    background-color: ${styles.colors.background};
    padding-bottom: 35px;
    margin-bottom: 35px;
`;

/**
 * 
 * @param {{src:string, title:string, body:string, style:React.CSSProperties, transform?:string colProps:ColProps}} props
 */
export default function TitledImageCard({ src, title, body, style, transform, colProps }) {
    return (
        <StyledCol {...colProps} className='g-0'>
            <Wrapper {...{ style }}>
                <ImageWrapper>
                    <Image {...{ src }} style={{ width: '100%', height: 'auto', transform: transform ? transform : 'translateY(-35px)'}} />
                </ImageWrapper>
                <TextWrapper>
                    <Text.Heading variant='bold' type='h5' style={{color: styles.colors.blue}}>{title}</Text.Heading>
                    <Text.Caption style={{ minHeight: '40px' }}>{body}</Text.Caption>
                </TextWrapper>
            </Wrapper>
        </StyledCol>
    )
}