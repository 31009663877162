import React from 'react'
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Progress from './Progress';
import Spinner from '../../shared/Spinner';
import Button from '../../shared/Button';
import Text from '../../shared/Text';
import { Leaf, Battery, Speed, Elevation, Temperature } from '../../../utility/icons';
import styles from '../../../utility/styles';

const Wrapper = styled.div`
    margin: 0 80px 80px 80px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledRow = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${styles.colors.darkGray};
`;

const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Pill = styled.div`
    display: ${({ showPill }) => showPill ? "flex" : "none"};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    max-width: 80vw;
    height: ${({ showPill }) => showPill ? "140px" : "0px"};
    max-height: 170px;
    margin: 32px auto 18px auto;
    border-radius: 16px;
    border: 3px solid ${styles.colors.darkGray};
    transition: visibility 1s;

    &:active {
        background-color: ${styles.colors.darkGray};
    }
`;

export default function LiveDrive({ data }) {

    const [isGray, setIsGray] = React.useState(() => (localStorage.getItem('wdpref') === 'true' || !data.real_world_range_early_live_string) ? false : true);

    React.useEffect(() => {
        if (!data) return;
        if (!data.real_world_range_early_live_string) setIsGray(false);
    }, [data])

    const toggleGray = () => {
        const pref = localStorage.getItem('wdpref')
        if (pref === 'true') {
            localStorage.setItem('wdpref', 'false')
            if (!data.real_world_range_early_live_string) return;
            setIsGray(true);
        } else {
            localStorage.setItem('wdpref', 'true')
            if (!data.real_world_range_early_live_string) return;
            setIsGray(false);
        }
    }

    if (!data.live_drive_detail || data.live_drive_loading) return (
        <Wrapper>
            <Row>
                <Col>

                    <StyledCol>
                        <Text.Heading variant='bold' style={{ textAlign: 'center', marginTop: '32px', marginBottom: '32px' }}>Starting Drive</Text.Heading>
                        <Text.Heading type='h4' style={{ textAlign: 'center', marginBottom: '16px' }}>Drive information is being calculated and will be available momentarily.</Text.Heading>
                        <Text.Heading type='h4' style={{ textAlign: 'center', marginBottom: '64px' }}>Have a safe drive!</Text.Heading>
                        <Spinner color={styles.colors.blue} size={100} />
                    </StyledCol>
                </Col>
            </Row>
        </Wrapper>
    )

    const { live_drive_detail } = data;

    const formatCurrentDriveDuration = ( ms ) => {
        let seconds = ms / 1000;
        const hours = parseInt( seconds / 3600 );
        seconds = seconds % 3600;
        let minutes = parseInt( seconds / 60 );
        seconds = parseInt(seconds % 60);
        if (hours > 0){
            return `${hours}hr ${minutes}min`;
        } else if (minutes > 0){
            return `${minutes}min`;
        } else {
            return "Just Started"
        }
    }
    const duration_string = live_drive_detail?.start_time_ms ? formatCurrentDriveDuration( (new Date()).getTime() - live_drive_detail?.start_time_ms ) : null;

    return (
        <Wrapper>
            <Button hideShadow style={{ backgroundColor: 'transparent', padding: 0 }} onClick={toggleGray} disabled={!data.real_world_range_early_live_string}>
                <Row>
                    <StyledCol>
                        <ProgressWrapper>
                            <Col className='mx-5'>
                                <Progress.Horizontal
                                    title={isGray ? 'Median Drive Eff.' : 'Current Drive Eff.'}
                                    text={isGray ? `${Math.round(Number(data.real_world_median_eff) * 100)}%` : `${Math.round(Number(live_drive_detail.efficiency) * 100)}%`}
                                    percent={isGray ? Math.round(Number(data.real_world_median_eff) * 100) : Math.round(Number(live_drive_detail.efficiency) * 100)}
                                    Icon={Leaf}
                                    colorFunc={styles.functions.returnEfficiencyColorFromPercent}
                                    {...{ isGray }}
                                />
                            </Col>
                            <Col className='mx-5'>
                                <Progress.Horizontal
                                    title='Real World Range'
                                    text={isGray ? data.real_world_range_early_live_string : data.real_world_range_string}
                                    percent={data.battery_level}
                                    Icon={Battery}
                                    {...{ isGray }}
                                />
                            </Col>
                        </ProgressWrapper>
                        <Pill showPill={Boolean(data.real_world_range_early_live_string)} isGray={Boolean(isGray)}>
                            <Text.Heading variant='bold' type='h4' style={{ color: styles.colors.white, textAlign: 'center', marginBottom: '8px' }}>Crunching the numbers...</Text.Heading>
                            <Text.Heading type='h6' style={{ color: styles.colors.lightGray, textAlign: 'center', marginBottom: '16px' }}>
                                Your range based on this drive will be ready in a few minutes once more data is available.
                            </Text.Heading>
                            <Text.Heading variant='bold' type='h6' style={{ color: styles.colors.lightGray, textAlign: 'center' }}>Tap to show {isGray ? 'live values' : ' values based on recent driving'}.</Text.Heading>
                        </Pill>
                    </StyledCol>
                </Row>
            </Button>
            <Row>
                <Col>
                    <StyledCol>
                        <Speed />
                        <Text.Huge variant='bold' type='mega'>{live_drive_detail.avg_speed_string ?? '-'}</Text.Huge>
                        <Text.Heading variant='bold' type='h6' style={{ color: styles.colors.lightGray }}>Avg. Speed</Text.Heading>
                    </StyledCol>
                </Col>
                <Col>
                    <StyledCol>
                        <Elevation />
                        <Text.Huge variant='bold' type='mega'>{live_drive_detail.elev_change_string ?? '-'}</Text.Huge>
                        <Text.Heading variant='bold' type='h6' style={{ color: styles.colors.lightGray }}>Elevation Change</Text.Heading>
                    </StyledCol>
                </Col>
                <Col>
                    <StyledCol>
                        <Temperature />
                        <Text.Huge variant='bold' type='mega'>{live_drive_detail.avg_temp_string ?? '-'}</Text.Huge>
                        <Text.Heading variant='bold' type='h6' style={{ color: styles.colors.lightGray }}>Avg. Temperature</Text.Heading>
                    </StyledCol>
                </Col>
            </Row>
            <Row>
                <StyledRow style={{ padding: '0' }}>
                    <StyledCol style={{padding: '32px 0 32px 32px', alignItems: 'flex-start', flex: 1}}>
                        <Text.Heading type='h4' variant='bold'>{duration_string ?? '-'}</Text.Heading>
                        <Text.Heading type='h6' variant='bold' style={{ color: styles.colors.lightGray }}>Duration</Text.Heading>
                    </StyledCol>
                    <StyledCol style={{padding: '32px 0 32px 32px', alignItems: 'flex-start', flex: 1}}>
                        <Text.Heading type='h4' variant='bold'>{live_drive_detail.distance_traveled_string ?? '-'}</Text.Heading>
                        <Text.Heading type='h6' variant='bold' style={{ color: styles.colors.lightGray }}>Distance</Text.Heading>
                    </StyledCol>
                    <StyledCol style={{padding: '32px 0', alignItems: 'flex-start', flex: 1}}>
                        <Text.Heading type='h4' variant='bold'>{live_drive_detail.range_diff_string ?? '-'} / {live_drive_detail.battery_diff_string ?? '-'}</Text.Heading>
                        <Text.Heading type='h6' variant='bold' style={{ color: styles.colors.lightGray }}>Range Used</Text.Heading>
                    </StyledCol>
                    <StyledCol style={{padding: '32px 0', alignItems: 'flex-start', flex: 1}}>
                        <Text.Heading type='h4' variant='bold'>{live_drive_detail.est_co2_string ?? '-'}</Text.Heading>
                        <Text.Heading type='h6' variant='bold' style={{ color: styles.colors.lightGray }}>Est. CO₂</Text.Heading>
                    </StyledCol>
                    <StyledCol style={{padding: '32px 0', alignItems: 'flex-start', flex: 1}}>
                        <Text.Heading type='h4' variant='bold'>{live_drive_detail.power_used_string ?? '-'}</Text.Heading>
                        <Text.Heading type='h6' variant='bold' style={{ color: styles.colors.lightGray }}>Est. Energy Used</Text.Heading>
                    </StyledCol>
                    <StyledCol style={{padding: '32px', backgroundColor: styles.colors.darkGray, flex: 0 }}>
                        <Text.Heading type='h4' variant='bold'>{live_drive_detail.elec_cost_string ?? '-'}</Text.Heading>
                        <Text.Heading type='h6' variant='bold' style={{ color: styles.colors.lightGray }}>Est. Cost</Text.Heading>
                    </StyledCol>
                </StyledRow>
            </Row>
        </Wrapper>
    )
}