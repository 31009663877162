import React from 'react';
import styled from 'styled-components';
import styles from '../../../utility/styles';
import Text from '../../shared/Text';
import Button from '../../shared/Button';

const MIN_VERSIONS = 10

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media only screen and ${styles.breakpoints.tablet} {
        margin-top: 16px;
    }
`

const MyRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid ${styles.colors.darkGray};
    border-bottom: ${({ isLast }) => isLast ? `1px solid ${styles.colors.darkGray}` : 'none'};
    width: 100%;
`

const VersionRow = styled.div`
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: space-around;
`

const TextRow = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`

const VersionStringWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`

const StyledButton = styled(Button)`
    align-self: center;
    margin: 0 auto;
    padding: 4px 8px;
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
`

/**
 * 
 * @param {{
 *   versions: {version: string, pct: number}[],
 *   currentVersion: string
 * }} props
 * @returns 
 */
export default function PreviousVersions({ versions = [], currentVersion }) {
    const [isExpanded, setIsExpanded] = React.useState(false)
    const toggleExpand = () => setIsExpanded(!isExpanded)
    const filtered = React.useMemo(() => {
        if (versions.length <= MIN_VERSIONS) return versions // if there are less than 10 versions, show all
        const numGreaterThanOne = versions.filter(v => v.pct >= 1).length
        // if there are less than 10 versions with more than 1% usage, show at least 10 versions
        const filteredVersions = numGreaterThanOne <= MIN_VERSIONS ? versions.slice(0, MIN_VERSIONS) : versions.filter(v => v.pct >= 1)
        // if expanded, show all versions, otherwise show the filtered versions
        return isExpanded ? versions : filteredVersions
    }, [isExpanded, versions])
    return (
        <Wrapper>
            {filtered.length < 1 && <Text.Caption>No Previous Versions</Text.Caption>}
            {filtered.map(({ version, pct }, i, arr) => (
                <MyRow key={version} isLast={i === arr.length - 1}>
                    <VersionRow>
                        <VersionStringWrapper>
                            <Text.Caption variant='bold' color={styles.colors.blue} style={{ border: `1px solid ${styles.colors.blue}`, borderRadius: '24px', padding: '4px 8px' }}>{version}</Text.Caption>
                        </VersionStringWrapper>
                        <VersionStringWrapper>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M16.67 13L13.09 16.59L14.5 18L20.5 12L14.5 6L13.09 7.41L16.67 11H4.5V13H16.67Z" fill={styles.colors.white} />
                            </svg>
                        </VersionStringWrapper>
                        <VersionStringWrapper>
                            <Text.Caption color={styles.colors.white}>{currentVersion}</Text.Caption>
                        </VersionStringWrapper>
                    </VersionRow>
                    <TextRow>
                        <Text.Caption>{pct}%</Text.Caption>
                    </TextRow>
                </MyRow>
            ))}
            {versions.length > MIN_VERSIONS && (
                <StyledButton onClick={toggleExpand} variant='secondary'>{isExpanded ? 'Show Less' : 'Show More'} Versions</StyledButton>
            )}
        </Wrapper>
    )
}