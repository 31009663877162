import React from 'react';
import { Container, Col, Row, Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledFloatingGroupLabel, StyledInput, ButtonWrapper, FlashWrapper } from '../shared/Password';
import ScreenWrapper from '../shared/ScreenWrapper';
import validator from 'validator';
import Text from '../shared/Text';
import Button from '../shared/Button';
import { Warning, AltDone } from '../../utility/icons';
import styles from '../../utility/styles';

const options = { 
    minLength: 6, 
    minLowercase: 0, 
    minUppercase: 0, 
    minNumbers: 0, 
    minSymbols: 0, 
    returnScore: false, 
    pointsPerUnique: 1, 
    pointsPerRepeat: 0.5, 
    pointsForContainingLower: 10, 
    pointsForContainingUpper: 10, 
    pointsForContainingNumber: 10, 
    pointsForContainingSymbol: 10 
}

const StyledContainer = styled(Container)`
    padding: 0 24px;
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 75px;
`;

const StyledSpan = styled.span`
    background-color: ${styles.colors.darkGray};
    border: none;
`;

export default function PasswordReset({ isValidToken, reset_password_token }) {
    const [password, setPassword] = React.useState('');
    const [confirm, setConfirm] = React.useState('');
    const [error, setError] = React.useState('');
    const [flash, setFlash] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handlePasswordchange = React.useCallback(({ target: { value } }) => {
        setPassword(value);
    }, [setPassword]);

    const handleConfirmChange = React.useCallback(({ target: { value } }) => {
        setConfirm(value);
    }, [setConfirm]);

    const handleSubmit = React.useCallback(async () => {
        try {
            setFlash('');
            setError('');
            setLoading(true);
            const response = await fetch('/v2/password/reset', {
                method: 'POST',
                body: JSON.stringify({
                    reset_password_token,
                    password,
                    password_confirmation: confirm
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) throw 'Error updating password';
            const { message, success } = await response.json();
            if (!success) throw message
            setFlash(message);
            setLoading(false);
        } catch (error) {
            setError(typeof error === 'string' ? error : 'Error updating password');
            setLoading(false);
        }
    }, [password, confirm, setFlash, setError, setLoading]);

    return (
        <ScreenWrapper>
            <StyledContainer>
                <Row style={{ maxWidth: '500px' }}>
                    <Col>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <Warning />
                        </div>
                        {isValidToken ? (
                            <React.Fragment>
                                <Text.Heading type='h2' variant='bold' style={{ textAlign: 'center', color: styles.colors.whiteGray }}>Change Password</Text.Heading>
                                <Text.Body style={{ textAlign: 'center', color: styles.colors.whiteGray, marginBottom: '24px' }}>Update your password below. Passwords must be a minimum of 4 characters in length.</Text.Body>
                                <Form onSubmit={e=>e.preventDefault()}>
                                    <InputGroup style={{ marginBottom: '24px' }}>
                                        <StyledFloatingGroupLabel
                                            controlId='floatingPasswordInput'
                                            label='New Password'
                                        >
                                            <StyledInput
                                                value={password}
                                                onChange={handlePasswordchange}
                                                placeholder='password'
                                                type='password'
                                            />
                                        </StyledFloatingGroupLabel>
                                        {validator.isStrongPassword(password, options) && (
                                            <StyledSpan className="input-group-addon">
                                                <AltDone />
                                            </StyledSpan>
                                        )}
                                    </InputGroup>
                                    <InputGroup style={{ marginBottom: '24px' }}>
                                        <StyledFloatingGroupLabel
                                            controlId='floatingConfirmInput'
                                            label='Confirm New Password'
                                        >
                                            <StyledInput
                                                value={confirm}
                                                onChange={handleConfirmChange}
                                                placeholder='confirm password'
                                                type='password'
                                            />
                                        </StyledFloatingGroupLabel>
                                        {validator.isStrongPassword(password, options) && validator.equals(password, confirm) && (
                                            <StyledSpan className="input-group-addon">
                                                <AltDone />
                                            </StyledSpan>
                                        )}
                                    </InputGroup>
                                </Form>
                                <ButtonWrapper>
                                    <Button
                                        onClick={handleSubmit}
                                        style={{ display: 'flex', flex: 1, marginBottom: '24px' }}
                                        disabled={!validator.equals(password, confirm) || !validator.isStrongPassword(password, options)}
                                        {...{ loading }}
                                    >
                                        Submit
                                    </Button>
                                </ButtonWrapper>
                                {Boolean(flash) && (
                                    <FlashWrapper variant='success'>
                                        <Text.Caption>{flash}</Text.Caption>
                                    </FlashWrapper>
                                )}
                                {Boolean(error) && (
                                    <FlashWrapper variant='error'>
                                        <Text.Caption>{error}</Text.Caption>
                                    </FlashWrapper>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Text.Heading type='h2' variant='bold' style={{ textAlign: 'center', color: styles.colors.whiteGray }}>Invalid Token</Text.Heading>
                                <Text.Body style={{ textAlign: 'center', color: styles.colors.whiteGray, marginBottom: '48px' }}>This token is no longer valid. Please follow the link below to request a fresh token.</Text.Body>
                                <Button as='a' href='/forgot_password'>Forgot Password</Button>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </StyledContainer>
        </ScreenWrapper>
    )
}