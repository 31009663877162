import React from 'react';
import Text from '../shared/Text';
import ScreenWrapper from '../shared/ScreenWrapper';
import { Col, Container } from 'react-bootstrap';


export default function WebDash({ session_id }) {

  const [ data, setData ] = React.useState({});
  const [ initalLoading, setInitialLoading ] = React.useState(true);
  const [isLoadingData, setIsLoadingData] = React.useState(false);
  const [loadInterval, setLoadInterval] = React.useRef(null);
  
  const loadData = React.useCallback( async() => {
    if (isLoadingData) return
    try{
      setIsLoadingData(true);
      const response = await fetch(`${session_id}/data`);
      if (!response.ok) throw 'Unable to load Dash data.';
      const data = await response.json();
      setData(data);
      setInitialLoading(false);
      setIsLoadingData(false);
    } catch (error) {
      console.error(error);
      setInitialLoading(false);
      setIsLoadingData(false);
    }
  }, [isLoadingData, setIsLoadingData, setInitialLoading]);

  React.useEffect(()=>{
    if( loadInterval ) clearInterval( loadInterval );
    setLoadInterval( setInterval( ()=> loadData(), 5000 ) );

    return () => {
      clearInterval(loadInterval)
    }
  },[]);

  React.useEffect(() => {
    localStorage.setItem("wdsid", JSON.stringify(session_id));
  }, [])

  if (initalLoading){
    return (
      <div>Loading...</div>
    )
  }

  if (data?.authenticated == false){  
    return (
      <>
        <div>Scan QR Code to Authorize</div>
        <img src={`${session_id}/qrcode.png`}/>
      </>
   
    )
  } else {
    return (
      <div>{JSON.stringify(data)}</div>
    )
  }
}
