import React from 'react'
import styled from 'styled-components'
import ScreenWrapper from '../shared/ScreenWrapper'
import styles from '../../utility/styles'
import { GiftSuccess } from '../../utility/icons'
import Text from '../shared/Text'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const Heading = styled(Text.Heading)`
	text-align: center;
	max-width: 60%;
	margin-bottom: 16px;
`

export default function GiveGiftSuccess({ data }) {
	if (!data) return false
	return (
		<ScreenWrapper>
			<Wrapper>
				<GiftSuccess style={{ marginBottom: '16px' }} />
				<Heading>Your Gift Order is Confirmed!</Heading>
				<Heading type='h5' variant='bold'>
					Order # {data.order_number}
				</Heading>
				<Heading type='h5'>
					An email has been sent to {data.to_email} with instructions on how they can redeem their gift.{'\n'}A confirmation with these instructions will also be sent to you.
				</Heading>
				<Heading type='h5'>Thanks for sharing TezLab with others!</Heading>
			</Wrapper>
		</ScreenWrapper>
	)
}
