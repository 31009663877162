import React from 'react';
import styled from 'styled-components';
import Text from '../Text';
import styles from '../../../utility/styles';
import moment from 'moment';

const StyledText = styled(Text.Tiny)`
    margin-right: 4px;
    color: ${styles.colors.lightGray};
`


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const Title = styled(Text.Body)`
    margin-bottom: 8px;
`;

const TimeWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const ChartWrapper = styled.div`
    width: 100%;
    height: 12px;
    border-radius: 2px;
    background-color: ${styles.colors.background};
    display: flex;
    margin-bottom: 8px;
`;

const Inactive = styled.div`
    background-color: ${styles.functions.opacityModifier(styles.colors.chargingGreen, 32)};
    width: ${({ start }) => `${start}%`};
    border-right: 2px solid ${styles.colors.background};
    height: 100%;
`;

const Active = styled.div`
    background-color: ${styles.colors.chargingGreen};
    width: ${({ width }) => `${width}%`};
    height: 100%;
`;

export default function Charge({ data }) {
    const {
        charge_start_percent,
        charge_start_string,
        charge_end_percent,
        charge_end_string,
        supercharger,
        user_charge_location,
        start_time_ms,
        stop_time_ms,
        trip_duration_string,
        range_diff_string,
        charge_energy_added_string
    } = data;

    const title = supercharger ? supercharger.title : user_charge_location ? user_charge_location.name : ''
    const startTimeText = React.useMemo(() => moment(start_time_ms).format('MMM DD h:mma'), [start_time_ms])

    return (
        <Wrapper>
            <Title variant='bold'>{title}</Title>
            <TimeWrapper>
                <StyledText variant='bold'>{startTimeText} • {trip_duration_string}</StyledText>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ marginRight: '4px' }}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.34337 10.7036C2.34366 10.869 2.47789 11.003 2.64337 11.003H10.0318H10.4497H12.0333C12.1989 11.003 12.3333 10.8687 12.3333 10.703V5.29696C12.3333 5.13128 12.1989 4.99696 12.0333 4.99696H10.3333H10.1137H2.63379C2.4679 4.99696 2.3335 5.1316 2.33379 5.2975L2.34337 10.7036ZM1.62972 12H9.22735H10.2092H13.0333C13.1989 12 13.3333 11.8657 13.3333 11.7V10H14.0333C14.1989 10 14.3333 9.86569 14.3333 9.7V6.3C14.3333 6.13431 14.1989 6 14.0333 6H13.3333V4.29999C13.3333 4.1343 13.1989 4 13.0333 4H11.3588H10.3547H1.63325C1.46757 4 1.33325 4.13431 1.33325 4.3V11.7C1.33325 11.8657 1.46404 12 1.62972 12ZM3.19993 5.66667C3.08947 5.66667 2.99993 5.75621 2.99993 5.86667V10.1333C2.99993 10.2438 3.08947 10.3333 3.19993 10.3333H9.4666C9.57705 10.3333 9.6666 10.2438 9.6666 10.1333V5.86667C9.6666 5.75621 9.57705 5.66667 9.4666 5.66667H3.19993Z" fill={styles.colors.lightGray} />
                </svg>
                <StyledText variant='bold'>{charge_start_percent}%</StyledText>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ marginRight: '4px' }}>
                    <path d="M10.7801 8.66667L8.39341 11.06L9.33341 12L13.3334 8L9.33341 4L8.39341 4.94L10.7801 7.33333H2.66675V8.66667H10.7801Z" fill={styles.colors.lightGray} />
                </svg>
                <StyledText variant='bold'>{charge_end_percent}%</StyledText>
            </TimeWrapper>
            <ChartWrapper>
                <Inactive start={charge_start_percent} />
                <Active width={charge_end_percent - charge_start_percent ?? 0} />
            </ChartWrapper>
        </Wrapper>
    )
}
