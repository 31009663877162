import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import Text from '../shared/Text';
import ScreenWrapper from '../shared/ScreenWrapper';

const StyledContainer = styled(Container)`
    margin-top: 75px;
    margin-bottom: 75px;
    padding: 0 2rem;
`;

export default function Policy({ heading, body, isWebView }) {
    return (
        <ScreenWrapper {...{ isWebView }}>
            <StyledContainer>
                <Text.Heading style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: heading }} />
                <Text.Body dangerouslySetInnerHTML={{ __html: body }} />
            </StyledContainer>
        </ScreenWrapper>
    )
}