import React from 'react';
import styled from 'styled-components';
import styles from '../../utility/styles';
import Text from './Text';

const Wrapper = styled.div`
    display:${({showOnTablet}) => showOnTablet ? 'none' : 'flex'};
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 235px;
    margin: 24px;
    @media only screen and ${styles.breakpoints.tablet} {
        display: flex;
        flex-direction: row;
        max-width: 100%;
    }
    @media only screen and ${styles.breakpoints.massive} {
        max-width: 345px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and ${styles.breakpoints.tablet} {
        align-items: flex-start;
    }
`;

const StyledCaption = styled(Text.Caption)`
    text-align: center;
    font-weight: 100;
    color: ${styles.colors.whiteGray};
    @media only screen and ${styles.breakpoints.tablet} {
        text-align: start;
    }
`;

const ImageWrapper = styled.div`
    margin-bottom: 20px;
    @media only screen and ${styles.breakpoints.tablet} {
        margin: 0 24px;
    }
`;

export default function InfoCard({ imageElement, title, body, showOnTablet }) {
    return (
        <Wrapper {...{ showOnTablet }}>
            <ImageWrapper>
                {Boolean(imageElement) && React.cloneElement(imageElement)}
            </ImageWrapper>
            <TextWrapper>
                <Text.Heading variant='bold' type='h5' style={{ marginBottom: '8px', color: styles.colors.whiteGray }}>{title}</Text.Heading>
                <StyledCaption>{body}</StyledCaption>
            </TextWrapper>
        </Wrapper>
    )
}