import React from 'react';
import styled from 'styled-components';
import Text from '../../shared/Text';
import styles from '../../../utility/styles';

const VersionSection = styled.div`
    @media only screen and (max-width: 768px) {
        margin-top: 12px
    }
`

const MyRow = styled.div`
    display: flex;
    justify-content: ${({ justify }) => justify || 'flex-start'};
    align-items: center;
    margin-bottom: ${({ margin }) => margin || '4px'};
`

const BarFront = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: ${styles.colors.white};
`

const BarBack = styled.div`
    position: relative;
    width: 100%;
    height: 8px;
    border-radius: 2px;
    margin-bottom: 4px;
    background-color: ${({ background }) => background};
    overflow: hidden;
`

const Bar = ({ fraction, background = styles.colors.white }) => (
    <BarBack {...{ background }} >
        <BarFront style={{ width: `${fraction * 100}%` }} />
    </BarBack>
)


export default function Models({ models = [] }) {

    return (
        <VersionSection>
            <Text.Tiny variant='bold' color={styles.colors.lightGray}>Model Distribution</Text.Tiny>
            {!models.length ? (
                <MyRow>
                    <Text.Caption variant='bold'>No Data</Text.Caption>
                </MyRow>
            ) : (
                <React.Fragment>
                    {models.map(({ model_name, pct }) => (
                        <MyRow justify='space-between' key={model_name}>
                            <MyRow style={{ flex: 1 }}>
                                <Text.Caption variant='bold' style={{ marginRight: '4px' }}>{model_name}</Text.Caption>
                                <Text.Caption>{pct}%</Text.Caption>
                            </MyRow>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <Bar fraction={pct / 100} background={styles.colors.darkGray} />
                            </div>
                        </MyRow>
                    ))}
                </React.Fragment>
            )}
        </VersionSection>
    )
}